import { toString as toString_1, Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, bool_type, string_type, union_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { Post, GenericResponse_$reflection, Post_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { utcDateToLocalDate, toPercentageChange, utcStringToTimePeriod, utcStringToLocalTimeSpan, toISO8601, checkError } from "../Utils.js";
import { utcNow } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { useReact_useCallback_1CA17B65, useReact_useRef_1505, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { MenuItem, Popper, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog } from "@mui/material";
import { User_usernameText, Image_userCircle, wrapTooltip, smallIconButton, pillButtonSmall, loadingIndicatorSmall, errorAlert } from "../ViewHelpers.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { StockRating__ToTextColorCode, StockRating_FromInt_Z524259A4, StockRating, StockRating__ToColorCode } from "../Shared/Shared.js";
import { printf, toText, format } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { DefaultBorder, Dimensions_TextSmall } from "../Design.js";
import { Report as Report_1, ReportObject } from "./ReportForm.js";
import { EditForm as EditForm_1 } from "./EditPost.js";
import { Content } from "./ContentParser.js";
import { ParentContent, Replies } from "./Replies.js";
import { PostType as PostType_1, Post as Post_1 } from "./SystemPost.js";

export class PostType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssetReview", "AssetPost", "ShortEvent", "InsiderTrade"];
    }
}

export function PostType_$reflection() {
    return union_type("Investfora.Post.PostType", [], PostType, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function PostType__get_UpvoteUrl(this$) {
    switch (this$.tag) {
        case 1:
            return `/api/asset/post/upvote/${this$.fields[0]}`;
        case 2:
        case 3:
            return "";
        default:
            return `/api/review/upvote/${this$.fields[0]}`;
    }
}

export function PostType__get_DownVoteUrl(this$) {
    switch (this$.tag) {
        case 1:
            return `/api/asset/post/downvote/${this$.fields[0]}`;
        case 2:
        case 3:
            return "";
        default:
            return `/api/review/downvote/${this$.fields[0]}`;
    }
}

export function PostType__get_DeleteUrl(this$) {
    switch (this$.tag) {
        case 1:
            return `/api/asset/post/${this$.fields[0]}`;
        case 2:
        case 3:
            return "";
        default:
            return `/api/review/${this$.fields[0]}`;
    }
}

class Model extends Record {
    constructor(Post, Type, Reply, Expanded, MenuOpen, DeleteForm, EditForm, Report, CompId) {
        super();
        this.Post = Post;
        this.Type = Type;
        this.Reply = Reply;
        this.Expanded = Expanded;
        this.MenuOpen = MenuOpen;
        this.DeleteForm = DeleteForm;
        this.EditForm = EditForm;
        this.Report = Report;
        this.CompId = CompId;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Post.Model", [], Model, () => [["Post", Post_$reflection()], ["Type", PostType_$reflection()], ["Reply", string_type], ["Expanded", bool_type], ["MenuOpen", bool_type], ["DeleteForm", bool_type], ["EditForm", bool_type], ["Report", bool_type], ["CompId", string_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateReply", "Expand", "Upvote", "DownVote", "UpvoteResponse", "DownVoteResponse", "ToggleMenuOpen", "ToggleDeleteForm", "ContentUpdated", "ToggleReport", "ToggleEdit"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Post.Message", [], Message, () => [[["Item", string_type]], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [], []]);
}

function init(x, y, b) {
    return new Model(x, y, "", false, false, false, false, false, b);
}

function update(model, msg) {
    let bind$0040, matchValue, bind$0040_1, matchValue_1, bind$0040_2, now, matchValue_2;
    switch (msg.tag) {
        case 1:
            return new Model(model.Post, model.Type, model.Reply, true, model.MenuOpen, model.DeleteForm, model.EditForm, model.Report, model.CompId);
        case 2:
            return new Model((bind$0040 = model.Post, new Post(bind$0040.Id, bind$0040.Type, bind$0040.Content, bind$0040.Rating, bind$0040.User, (matchValue = (model.Post.VoteState | 0), (matchValue === -1) ? (model.Post.Votes + 2) : ((matchValue === 1) ? model.Post.Votes : (model.Post.Votes + 1))), bind$0040.NumVotes, bind$0040.Published, 1, bind$0040.IsMine, bind$0040.Replies, bind$0040.NumReplies, bind$0040.Deleted, bind$0040.Updated, bind$0040.LinkPreview, bind$0040.ReviewInfo, bind$0040.AssetInfo, bind$0040.GroupInfo, bind$0040.ShortInfo, bind$0040.InsiderInfo)), model.Type, model.Reply, model.Expanded, model.MenuOpen, model.DeleteForm, model.EditForm, model.Report, model.CompId);
        case 3:
            return new Model((bind$0040_1 = model.Post, new Post(bind$0040_1.Id, bind$0040_1.Type, bind$0040_1.Content, bind$0040_1.Rating, bind$0040_1.User, (matchValue_1 = (model.Post.VoteState | 0), (matchValue_1 === -1) ? model.Post.Votes : ((matchValue_1 === 1) ? (model.Post.Votes - 2) : (model.Post.Votes - 1))), bind$0040_1.NumVotes, bind$0040_1.Published, -1, bind$0040_1.IsMine, bind$0040_1.Replies, bind$0040_1.NumReplies, bind$0040_1.Deleted, bind$0040_1.Updated, bind$0040_1.LinkPreview, bind$0040_1.ReviewInfo, bind$0040_1.AssetInfo, bind$0040_1.GroupInfo, bind$0040_1.ShortInfo, bind$0040_1.InsiderInfo)), model.Type, model.Reply, model.Expanded, model.MenuOpen, model.DeleteForm, model.EditForm, model.Report, model.CompId);
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkError(res.fields[0]);
                return model;
            }
            else {
                return model;
            }
        }
        case 5: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                checkError(res_1.fields[0]);
                return model;
            }
            else {
                return model;
            }
        }
        case 6:
            return new Model(model.Post, model.Type, model.Reply, model.Expanded, msg.fields[0], model.DeleteForm, model.EditForm, model.Report, model.CompId);
        case 7:
            return new Model(model.Post, model.Type, model.Reply, model.Expanded, false, msg.fields[0], model.EditForm, model.Report, model.CompId);
        case 8:
            return new Model((bind$0040_2 = model.Post, new Post(bind$0040_2.Id, bind$0040_2.Type, msg.fields[0], bind$0040_2.Rating, bind$0040_2.User, bind$0040_2.Votes, bind$0040_2.NumVotes, bind$0040_2.Published, bind$0040_2.VoteState, bind$0040_2.IsMine, bind$0040_2.Replies, bind$0040_2.NumReplies, bind$0040_2.Deleted, (now = toISO8601(utcNow()), (matchValue_2 = model.Post.Updated, (matchValue_2 != null) ? {
                Count: matchValue_2.Count + 1,
                Updated: now,
            } : {
                Count: 1,
                Updated: now,
            })), bind$0040_2.LinkPreview, bind$0040_2.ReviewInfo, bind$0040_2.AssetInfo, bind$0040_2.GroupInfo, bind$0040_2.ShortInfo, bind$0040_2.InsiderInfo)), model.Type, model.Reply, model.Expanded, model.MenuOpen, model.DeleteForm, model.EditForm, model.Report, model.CompId);
        case 9:
            return new Model(model.Post, model.Type, model.Reply, model.Expanded, model.MenuOpen, model.DeleteForm, model.EditForm, !model.Report, model.CompId);
        case 10:
            return new Model(model.Post, model.Type, model.Reply, model.Expanded, false, model.DeleteForm, !model.EditForm, model.Report, model.CompId);
        default:
            return new Model(model.Post, model.Type, msg.fields[0], model.Expanded, model.MenuOpen, model.DeleteForm, model.EditForm, model.Report, model.CompId);
    }
}

function upvote(x, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(2, []));
        const requestPath = PostType__get_UpvoteUrl(x);
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(4, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function downVote(x, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(3, []));
        const requestPath = PostType__get_DownVoteUrl(x);
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(5, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function DeleteForm(deleteFormInputProps) {
    let elements_2, children, children_1;
    const onClose = deleteFormInputProps.onClose;
    const target = deleteFormInputProps.target;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setErrMsg = patternInput_1[1];
    const targetText = (target.tag === 0) ? "denne analysen" : "dette innlegget";
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", (target.tag === 0) ? "Slett analyse" : "Slett innlegg"]]), (children = ofArray([MuiHelpers_createElement(DialogContentText, [["children", `Er du sikker på at du ønsker å slette ${targetText}?`]]), errorAlert(patternInput_1[0], (_arg_5) => {
        setErrMsg(undefined);
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput[0] ? singleton_1(loadingIndicatorSmall()) : append(singleton_1(pillButtonSmall(ofArray([["backgroundColor", "var(--bg-main)"], ["color", "var(--text-main)"]]), "Avbryt", (_arg_6) => {
        onClose(false);
    })), delay(() => singleton_1(pillButtonSmall(empty(), "Slett", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            setProcessing(true);
            const requestPath = PostType__get_DeleteUrl(target);
            return ((decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })()))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 0) {
                    const x_1 = res.fields[0];
                    if (x_1.Result === "success") {
                        onClose(true);
                        return Promise.resolve();
                    }
                    else {
                        setProcessing(false);
                        setErrMsg(x_1.Message);
                        return Promise.resolve();
                    }
                }
                else {
                    setErrMsg("Kunne ikke slette innlegg. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    }))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function drawPostHeader(model, dispatch, menuAnchor) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", "post-header"], (elems_1 = toList(delay(() => {
        let elems;
        const matchValue = model.Post.AssetInfo;
        if (matchValue != null) {
            const si = matchValue;
            return append(singleton_1(createElement("a", createObj(ofArray([["href", `/stock/${si.Market}/${si.Ticker}`], (elems = [createElement("span", {
                style: {
                    fontSize: 1.225 + "rem",
                },
                children: si.Name,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => ((isAuthenticated) ? singleton_1(createElement("div", {
                ref: menuAnchor,
                style: {
                    padding: ((0 + "px ") + 5) + "px",
                },
                children: smallIconButton("fas fa-ellipsis-h", (_arg) => {
                    dispatch(new Message(6, [!model.MenuOpen]));
                }),
            })) : empty_1())));
        }
        else {
            return singleton_1(createElement("span", {
                children: "Mangler aksjeinformasjon. Vennligst prøv igjen senere.",
            }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function updateInfo(x) {
    let elems;
    if (x != null) {
        const y = x;
        let txt;
        const upd = utcStringToLocalTimeSpan(y.Updated).toLocaleLowerCase();
        txt = ((y.Count === 1) ? (`Oppdatert ${upd}.`) : (`Oppdatert ${y.Count} ganger. Sist oppdatert ${upd}.`));
        return createElement("div", createObj(ofArray([["style", {
            padding: ((((((0 + "px ") + 5) + "px ") + 5) + "px ") + 10) + "px",
            backgroundColor: "#FFFFFF",
            fontSize: 0.7 + "rem",
            fontStyle: "italic",
            color: "var(--text-dim)",
            textAlign: "left",
        }], (elems = [createElement("span", {
            children: [txt],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return defaultOf();
    }
}

function drawLinkPreview(lp) {
    let elems_2;
    if (lp != null) {
        const x = lp;
        return createElement("a", {
            href: x.Url,
            target: "_blank",
            children: createElement("div", createObj(ofArray([["className", "link-preview-holder"], (elems_2 = toList(delay(() => {
                let matchValue, iu, elems;
                return append((matchValue = x.ImageUrl, (matchValue != null) ? ((iu = matchValue, singleton_1(createElement("div", createObj(ofArray([["style", {
                    maxWidth: 120,
                    maxHeight: 120,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }], (elems = [createElement("img", {
                    src: iu,
                    style: {
                        maxWidth: 100 + "%",
                        maxHeight: 100 + "%",
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))))) : (empty_1())), delay(() => {
                    let elems_1;
                    return singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexDirection: "column",
                        padding: ((0 + "px ") + 5) + "px",
                    }], (elems_1 = [createElement("span", {
                        className: "link-preview-text",
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: x.Title,
                    }), createElement("span", {
                        className: "link-preview-text",
                        style: {
                            fontSize: 0.6 + "rem",
                            fontStyle: "italic",
                        },
                        children: x.Desc,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))),
        });
    }
    else {
        return defaultOf();
    }
}

function drawRating(type$0027, post_1, dispatch) {
    let elems_3, elems_2;
    return createElement("div", createObj(ofArray([["className", "post-rating-holder"], (elems_3 = [createElement("div", createObj(ofArray([["className", "post-rating"], (elems_2 = toList(delay(() => {
        let elems;
        return append((isAuthenticated) ? singleton_1(createElement("button", createObj(ofArray([["style", createObj(toList(delay(() => ((post_1.VoteState === 1) ? singleton_1(["color", StockRating__ToColorCode(new StockRating(0, []))]) : empty_1()))))], ["className", "vote-button"], ["disabled", post_1.Deleted], ["onClick", (_arg) => {
            if ((post_1.VoteState !== 1) && !post_1.Deleted) {
                upvote(type$0027, dispatch);
            }
        }], (elems = [createElement("span", {
            className: "fas fa-caret-up",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => append(singleton_1(createElement("span", {
            style: createObj(toList(delay(() => {
                let matchValue;
                return append(singleton_1(["color", !post_1.Deleted ? ((matchValue = (post_1.Votes | 0), (matchValue > 0) ? StockRating__ToColorCode(new StockRating(0, [])) : ((matchValue < 0) ? StockRating__ToColorCode(new StockRating(4, [])) : "var(--text-dim)"))) : "var(--text-dim)"]), delay(() => (!(isAuthenticated) ? singleton_1(["paddingRight", 20]) : empty_1())));
            }))),
            children: post_1.Votes,
        })), delay(() => {
            let elems_1;
            return (isAuthenticated) ? singleton_1(createElement("button", createObj(ofArray([["style", createObj(toList(delay(() => ((post_1.VoteState === -1) ? singleton_1(["color", StockRating__ToColorCode(new StockRating(4, []))]) : empty_1()))))], ["className", "vote-button"], ["disabled", post_1.Deleted], ["onClick", (_arg_1) => {
                if ((post_1.VoteState !== -1) && !post_1.Deleted) {
                    downVote(type$0027, dispatch);
                }
            }], (elems_1 = [createElement("span", {
                className: "fas fa-caret-down",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1();
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

function drawPostReviewInfo(ri) {
    let elems_6;
    return createElement("div", createObj(ofArray([["className", "post-review-info"], (elems_6 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            marginRight: 15,
        }], (elems = toList(delay(() => (ri.Expired ? append(singleton_1(createElement("span", {
            style: {
                color: "#FFA500",
                marginRight: 5,
            },
            className: "fas fa-exclamation-circle",
        })), delay(() => singleton_1(createElement("span", {
            children: ["Tidsperioden gitt for analysen er utløpt"],
        })))) : append(singleton_1(createElement("span", {
            style: {
                marginRight: 5,
            },
            className: "fas fa-clock",
        })), delay(() => singleton_1(createElement("span", {
            children: [utcStringToTimePeriod(ri.Until)],
        }))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_5;
            const matchValue = ri.TargetPrice;
            if (matchValue != null) {
                const tp = matchValue;
                return singleton_1(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }], (elems_5 = toList(delay(() => {
                    let elems_2, elems_1;
                    return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }], (elems_2 = [createElement("span", {
                        style: {
                            marginRight: 5,
                            color: "#DC143C",
                        },
                        className: "fas fa-bullseye",
                    }), createElement("span", createObj(ofArray([["style", {
                        marginRight: 15,
                    }], (elems_1 = toList(delay(() => append(singleton_1("Kursmål: "), delay(() => {
                        let arg;
                        return append(singleton_1(createElement("span", {
                            style: {
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                            },
                            children: (arg = format('{0:' + "0.00" + '}', tp), toText(printf("%s %s"))(arg)(ri.Currency)),
                        })), delay(() => {
                            let y;
                            const matchValue_1 = ri.ClosestClose;
                            if (matchValue_1 != null) {
                                const cc = matchValue_1;
                                const isLower = tp < cc.Close;
                                const change = toPercentageChange(tp, cc.Close);
                                return singleton_1((y = createElement("span", {
                                    style: {
                                        marginLeft: 5,
                                        fontWeight: "bold",
                                        color: isLower ? StockRating__ToColorCode(new StockRating(4, [])) : StockRating__ToColorCode(new StockRating(0, [])),
                                    },
                                    children: `(${format('{0:' + "0.00" + '}', change)}%)`,
                                }), wrapTooltip(`Basert på sluttkurs ${format('{0:' + "0.00" + '}', cc.Close)} ${ri.Currency} ${utcDateToLocalDate(cc.Date)}`, y)));
                            }
                            else {
                                return empty_1();
                            }
                        }));
                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                        let elems_4, y_1, elems_3, arg_2;
                        const matchValue_2 = ri.LastClose;
                        const matchValue_3 = ri.ClosestClose;
                        let matchResult, cc_1, lc;
                        if (matchValue_2 != null) {
                            if (matchValue_3 != null) {
                                matchResult = 0;
                                cc_1 = matchValue_3;
                                lc = matchValue_2;
                            }
                            else {
                                matchResult = 1;
                            }
                        }
                        else {
                            matchResult = 1;
                        }
                        switch (matchResult) {
                            case 0: {
                                const change_1 = toPercentageChange(lc.Close, cc_1.Close);
                                return singleton_1(createElement("div", createObj(ofArray([["style", {
                                    display: "flex",
                                    alignItems: "center",
                                }], (elems_4 = [createElement("span", {
                                    style: {
                                        marginRight: 5,
                                    },
                                    className: "fas fa-chart-line",
                                }), (y_1 = createElement("span", createObj(ofArray([["style", {
                                    marginRight: 15,
                                }], (elems_3 = ["Siden publisering: ", createElement("span", {
                                    style: {
                                        fontWeight: "bold",
                                        color: (change_1 < 0) ? StockRating__ToColorCode(new StockRating(4, [])) : StockRating__ToColorCode(new StockRating(0, [])),
                                    },
                                    children: (arg_2 = format('{0:' + "0.00" + '}', change_1), toText(printf("%s%%"))(arg_2)),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), wrapTooltip(`Basert på siste registrerte sluttkurs ${format('{0:' + "0.00" + '}', lc.Close)} ${ri.Currency} ${utcDateToLocalDate(lc.Date)} og nærmeste sluttkurs ved publisering ${format('{0:' + "0.00" + '}', cc_1.Close)} ${ri.Currency} ${utcDateToLocalDate(cc_1.Date)}`, y_1))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
                            }
                            default: {
                                return empty_1();
                            }
                        }
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
            }
            else {
                return empty_1();
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

function drawUserInfo(model) {
    const posted = utcStringToLocalTimeSpan(model.Post.Published);
    return createElement("div", createObj(toList(delay(() => append((model.Post.Type === "review") ? singleton_1(["style", {
        display: "flex",
    }]) : singleton_1(["className", "post-user-holder"]), delay(() => {
        let elems_6, elems_3, elems_2, elems_5;
        return singleton_1((elems_6 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flex: 0.5,
        }], (elems_3 = [createElement("div", createObj(toList(delay(() => append(singleton_1(["style", {
            marginRight: 10,
        }]), delay(() => {
            let elems_1, elems;
            return !model.Post.Deleted ? singleton_1((elems_1 = [createElement("a", createObj(ofArray([["href", `/user/${model.Post.User.Username}`], (elems = [Image_userCircle(model.Post.User.Id, 50, false, undefined)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])) : empty_1();
        })))))), createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
        }], (elems_2 = [User_usernameText(model.Post.User, model.Post.Deleted), createElement("span", {
            style: {
                fontSize: Dimensions_TextSmall,
            },
            children: posted,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flex: 0.5,
            alignItems: "center",
            padding: 3,
            justifyContent: "flex-end",
        }], (elems_5 = toList(delay(() => {
            let elems_4;
            if (model.Post.Type === "review") {
                const rating = StockRating_FromInt_Z524259A4(model.Post.Rating);
                return singleton_1(createElement("div", createObj(ofArray([["style", {
                    backgroundColor: StockRating__ToColorCode(rating),
                    padding: 10,
                    width: 100,
                    borderRadius: 5,
                    textAlign: "center",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    letterSpacing: "0.35px",
                    userSelect: "none",
                }], (elems_4 = [createElement("span", {
                    style: {
                        color: StockRating__ToTextColorCode(rating),
                    },
                    children: toString_1(rating),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
            }
            else {
                return empty_1();
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]));
    }))))));
}

export function PostComponent(postComponentInputProps) {
    let matchValue, elems_6;
    const onRemove = postComponentInputProps.onRemove;
    const post_1 = postComponentInputProps.post;
    const compId = postComponentInputProps.compId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(post_1, (matchValue = post_1.Type, (matchValue === "review") ? (new PostType(0, [post_1.Id])) : ((matchValue === "asset-post") ? (new PostType(1, [post_1.Id])) : (new PostType(2, [post_1.Id])))), compId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const tooLong = model_1.Post.Content.length > 500;
    const menuAnchor = useReact_useRef_1505(undefined);
    const onEdited = useReact_useCallback_1CA17B65((x) => {
        if (x != null) {
            dispatch(new Message(8, [x]));
        }
        dispatch(new Message(10, []));
    });
    return createElement("div", createObj(ofArray([["className", "post"], ["key", model_1.CompId], ["style", {
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        borderRadius: 5,
    }], (elems_6 = toList(delay(() => append(model_1.DeleteForm ? singleton_1(createElement(DeleteForm, {
        target: model_1.Type,
        onClose: (didDelete) => {
            if (!didDelete) {
                dispatch(new Message(7, [false]));
            }
            else {
                onRemove(model_1.Post.Id);
            }
        },
    })) : empty_1(), delay(() => {
        let tgt;
        return append(model_1.Report ? ((tgt = ((model_1.Post.Type === "asset-post") ? (new ReportObject(2, [model_1.Post.Id])) : (new ReportObject(0, [model_1.Post.Id]))), singleton_1(createElement(Report_1, {
            target: tgt,
            onClose: () => {
                dispatch(new Message(9, []));
            },
        })))) : empty_1(), delay(() => append(singleton_1(drawPostHeader(model_1, dispatch, menuAnchor)), delay(() => {
            let elements, elems;
            return append(singleton_1(MuiHelpers_createElement(Popper, [["anchorEl", menuAnchor.current], ["open", model_1.MenuOpen], ["placement", "bottom-end"], (elements = [createElement("div", createObj(ofArray([["style", createObj(ofArray([["display", "flex"], ["flexDirection", "column"], ["backgroundColor", "#FFFFFF"], DefaultBorder, ["borderRadius", 5]]))], (elems = toList(delay(() => ((model_1.Post.IsMine && !model_1.Post.Deleted) ? append(singleton_1(MuiHelpers_createElement(MenuItem, [["onClick", (_arg_1) => {
                dispatch(new Message(10, []));
            }], ["children", "Rediger"]])), delay(() => singleton_1(MuiHelpers_createElement(MenuItem, [["onClick", (_arg_2) => {
                dispatch(new Message(7, [true]));
            }], ["children", "Slett"]])))) : ((!model_1.Post.Deleted && (isAuthenticated)) ? singleton_1(MuiHelpers_createElement(MenuItem, [["onClick", (_arg_3) => {
                dispatch(new Message(9, []));
            }], ["children", "Rapporter"]])) : empty_1())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
                let elems_4, elems_3;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", "post-content-holder"], (elems_4 = [createElement("div", createObj(ofArray([["className", "post-content"], (elems_3 = toList(delay(() => append(singleton_1(drawUserInfo(model_1)), delay(() => {
                    let matchValue_2;
                    return append((model_1.Post.Type === "review") ? ((matchValue_2 = model_1.Post.ReviewInfo, (matchValue_2 != null) ? singleton_1(drawPostReviewInfo(matchValue_2)) : (empty_1()))) : empty_1(), delay(() => {
                        let elems_2;
                        return model_1.EditForm ? singleton_1(createElement(EditForm_1, {
                            post: model_1.Post,
                            onClose: onEdited,
                        })) : ((model_1.Post.Content.trim().length > 0) ? append(singleton_1(createElement("div", createObj(ofArray([["style", {
                            padding: ((10 + "px ") + 0) + "px",
                        }], (elems_2 = [createElement(Content, {
                            content: model_1.Post.Content,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => singleton_1(drawLinkPreview(model_1.Post.LinkPreview)))) : empty_1());
                    }));
                })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), updateInfo(model_1.Post.Updated), drawRating(model_1.Type, model_1.Post, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_5;
                    return singleton_1(createElement("div", createObj(ofArray([["style", {
                        "grid-row": 3,
                        "grid-column": 1,
                    }], (elems_5 = toList(delay(() => {
                        const matchValue_3 = model_1.Type;
                        switch (matchValue_3.tag) {
                            case 0:
                                return singleton_1(createElement(Replies, {
                                    parent: new ParentContent(0, [model_1.Post.Id]),
                                    replies: model_1.Post.Replies,
                                    numReplies: model_1.Post.NumReplies,
                                }));
                            case 1:
                                return singleton_1(createElement(Replies, {
                                    parent: new ParentContent(1, [matchValue_3.fields[0]]),
                                    replies: model_1.Post.Replies,
                                    numReplies: model_1.Post.NumReplies,
                                }));
                            default: {
                                return empty_1();
                            }
                        }
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
                }));
            }));
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function post(post_1, onRemove) {
    let elems;
    let compId;
    const matchValue = post_1.Type;
    compId = ((matchValue === "review") ? (`review-post-${post_1.Id}`) : ((matchValue === "short-event") ? (`short-post-${post_1.Id}`) : ((matchValue === "insider-trade") ? (`insider-trade-post-${post_1.Id}`) : (`stock-post-${post_1.Id}`))));
    return createElement("div", createObj(ofArray([["key", compId], (elems = toList(delay(() => ((post_1.Type === "short-event") ? singleton_1(createElement(Post_1, {
        compId: compId,
        "type\'": new PostType_1(0, []),
        post: post_1,
    })) : ((post_1.Type === "insider-trade") ? singleton_1(createElement(Post_1, {
        compId: compId,
        "type\'": new PostType_1(1, []),
        post: post_1,
    })) : singleton_1(createElement(PostComponent, {
        compId: compId,
        post: post_1,
        onRemove: onRemove,
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

