import { Union, Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, record_type, bool_type, string_type, option_type, array_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { Post_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { map, sort, tryHead, sortByDescending, append } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { parseAsUtc } from "../Utils.js";
import { Array_distinctBy } from "../fable_modules/fable-library-js.4.17.0/Seq2.js";
import { createObj, uncurry2, comparePrimitives, numberHash } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { compare } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { empty as empty_1, append as append_1, singleton, delay, toList, fold } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { unwrap, map as map_1, defaultArg, toArray } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { post } from "../Content/Post.js";
import { loadingIndicator } from "../ViewHelpers.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";

class Model extends Record {
    constructor(UserId, Posts, ErrorMsg, LastReviewId, More) {
        super();
        this.UserId = (UserId | 0);
        this.Posts = Posts;
        this.ErrorMsg = ErrorMsg;
        this.LastReviewId = LastReviewId;
        this.More = More;
    }
}

function Model_$reflection() {
    return record_type("Investfora.UserFeed.Model", [], Model, () => [["UserId", int32_type], ["Posts", option_type(array_type(Post_$reflection()))], ["ErrorMsg", option_type(string_type)], ["LastReviewId", option_type(int32_type)], ["More", bool_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PostsResponse", "AppendPost", "RemovePost", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.UserFeed.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Post_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Post_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", Post_$reflection()]], [["Item", int32_type]], []]);
}

function init(userId) {
    return new Model(userId, undefined, undefined, undefined, true);
}

function update(model, msg) {
    let matchValue_2, matchValue_3, array_6, matchValue, lowest, matchValue_1, l;
    switch (msg.tag) {
        case 1: {
            const x_4 = msg.fields[0];
            return new Model(model.UserId, (matchValue_2 = model.Posts, (matchValue_2 == null) ? [x_4] : append([x_4], matchValue_2)), model.ErrorMsg, model.LastReviewId, model.More);
        }
        case 2:
            return new Model(model.UserId, (matchValue_3 = model.Posts, (matchValue_3 != null) ? ((array_6 = matchValue_3, array_6.filter((a) => (a.Id !== msg.fields[0])))) : undefined), model.ErrorMsg, model.LastReviewId, model.More);
        case 3:
            return new Model(model.UserId, model.Posts, undefined, model.LastReviewId, model.More);
        default: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                const x = res.fields[0];
                const p = sortByDescending((z_1) => parseAsUtc(z_1.Published), Array_distinctBy((z) => z.Id, (matchValue = model.Posts, (matchValue != null) ? append(matchValue, x) : x), {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: numberHash,
                }), {
                    Compare: compare,
                });
                return new Model(model.UserId, p, model.ErrorMsg, (lowest = tryHead(sort(map((y_4) => y_4.Id, p.filter((y_3) => (y_3.Type === "review")), Int32Array), {
                    Compare: comparePrimitives,
                })), (matchValue_1 = model.LastReviewId, (matchValue_1 != null) ? ((l = (matchValue_1 | 0), fold((_arg, z_2) => {
                    if (z_2 < l) {
                        return z_2 | 0;
                    }
                    else {
                        return l | 0;
                    }
                }, l, toArray(lowest)))) : lowest)), x.length > 0);
            }
            else {
                return new Model(model.UserId, model.Posts, "Something went wrong. Could not load reviews.", model.LastReviewId, model.More);
            }
        }
    }
}

function fetchUserPosts(userId, revId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const r = fold((_arg, x) => (`?rid=${x}`), "", toArray(revId));
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Post_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/user/mostrecent/${userId}${r}`, properties_3).then((_arg_1) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Post_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_3) => {
            dispatch(new Message(0, [_arg_3]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function noContentMessage(header, txt) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        height: 100 + "%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }], (elems = [createElement("span", {
        children: [header],
    }), createElement("br", {}), createElement("p", {
        children: [txt],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function view(model, dispatch) {
    let elems_2;
    const matchValue = model.Posts;
    if (matchValue != null) {
        const x = matchValue;
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            gap: (((1 + "vw") + " ") + 0) + "px",
            width: 100 + "%",
        }], (elems_2 = toList(delay(() => {
            let elems, xs_1;
            return (x.length === 0) ? singleton(createElement("div", createObj(ofArray([["style", {
                height: 100 + "%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }], (elems = [createElement("p", {
                children: ["Denne brukeren har ikke publisert noen analyser enda."],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : append_1(singleton((xs_1 = map((y) => post(y, (arg) => {
                dispatch(new Message(2, [arg]));
            }), x), react.createElement(react.Fragment, {}, ...xs_1))), delay(() => {
                let elems_1;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    textAlign: "center",
                }], (elems_1 = toList(delay(() => (model.More ? singleton(createElement("button", {
                    className: "pill-button-small",
                    onClick: (_arg) => {
                        fetchUserPosts(model.UserId, model.LastReviewId, dispatch);
                    },
                    children: "Mer",
                })) : empty_1()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        return loadingIndicator();
    }
}

export function Feed(feedInputProps) {
    const userId = feedInputProps.userId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(userId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchUserPosts(userId, model_1.LastReviewId, dispatch);
    });
    return view(model_1, dispatch);
}

