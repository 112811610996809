import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, option_type, string_type, bool_type, union_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { uncurry2, createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { cons, empty, singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { CommentForm } from "./CommentForm.js";
import { append, singleton, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { snackError, loadingIndicatorSmall } from "../ViewHelpers.js";
import { unwrap, map, defaultArg, some } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Post_$reflection, Post, User as User_1 } from "../Shared/ApiDataTypes.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";

export class Target extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssetPost", "NoTarget"];
    }
}

export function Target_$reflection() {
    return union_type("Investfora.PostForm.Target", [], Target, () => [[["Item", int32_type]], []]);
}

export function Target__ToFormId(this$) {
    if (this$.tag === 1) {
        return "post-form";
    }
    else {
        return `asset-post-form-${this$.fields[0]}`;
    }
}

export function Target__ToRequestPath(this$) {
    if (this$.tag === 1) {
        return "";
    }
    else {
        return toText(printf("/api/asset/post/%i"))(this$.fields[0]);
    }
}

export function Target__ToPostType(this$) {
    if (this$.tag === 1) {
        return "";
    }
    else {
        return "asset-post";
    }
}

class Model extends Record {
    constructor(Active, ValidComment, Processing, Published, ErrorMsg) {
        super();
        this.Active = Active;
        this.ValidComment = ValidComment;
        this.Processing = Processing;
        this.Published = Published;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.PostForm.Model", [], Model, () => [["Active", bool_type], ["ValidComment", bool_type], ["Processing", bool_type], ["Published", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

export function PostForm(postFormInputProps) {
    let elems_2, elems_1, elems;
    const onClose = postFormInputProps.onClose;
    const autoActive = postFormInputProps.autoActive;
    const onPublish = postFormInputProps.onPublish;
    const target = postFormInputProps.target;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const comment = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Model(autoActive, true, false, false, undefined));
    const updateModel = patternInput_1[1];
    const model = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_2[1];
    const formId = Target__ToFormId(target);
    if (model.Active) {
        if (model.Published) {
            return createElement("div", createObj(ofArray([["className", "review-form"], ["style", {
                padding: 5,
            }], (elems_2 = [createElement("span", {
                style: {
                    display: "block",
                    textAlign: "center",
                    fontWeight: 500,
                    fontSize: 1.2 + "rem",
                },
                children: "Your post has been published!",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }
        else {
            return createElement("div", createObj(ofArray([["className", "review-form"], (elems_1 = [createElement(CommentForm, {
                formId: formId,
                html: comment,
                onChange: patternInput[1],
            }), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "flex-end",
                padding: 5,
            }], (elems = toList(delay(() => (patternInput_2[0] ? singleton(loadingIndicatorSmall()) : append(singleton(createElement("button", {
                disabled: model.Processing,
                style: {
                    marginRight: 10,
                },
                className: "pill-button-small",
                onClick: (_arg_3) => {
                    if (!model.Processing) {
                        onClose();
                        updateModel(new Model(false, model.ValidComment, model.Processing, model.Published, model.ErrorMsg));
                    }
                },
                children: "Avbryt",
            })), delay(() => singleton(createElement("button", {
                disabled: model.Processing ? true : (comment.length === 0),
                className: "pill-button-small",
                onClick: (_arg_4) => {
                    if (!model.Processing) {
                        let hasComment;
                        try {
                            const x = document.getElementById(formId);
                            if (!(x == null)) {
                                const y = x.textContent;
                                console.log(some(toText(printf("text: %s"))(y)));
                                hasComment = (y.length > 0);
                            }
                            else {
                                hasComment = false;
                            }
                        }
                        catch (e) {
                            console.log(some(e));
                            hasComment = false;
                        }
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let body, decoder, data_1, caseStrategy_3, extra_3;
                            if (!hasComment) {
                                updateModel(new Model(model.Active, false, model.Processing, model.Published, model.ErrorMsg));
                                return Promise.resolve();
                            }
                            else {
                                setProcessing(true);
                                updateModel(new Model(model.Active, true, model.Processing, model.Published, model.ErrorMsg));
                                const requestPath = Target__ToRequestPath(target);
                                const req = new Post(-1, Target__ToPostType(target), comment, 0, new User_1(loggedInUserId, loggedInUsername, false), 0, 0, "", 0, true, [], 0, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                                return ((body = Auto_generateBoxedEncoder_437914C6(Post_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(Post_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                                    let properties_2;
                                    try {
                                        const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                            let response_1, decoder_1_1;
                                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Post_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                                let matchValue;
                                                return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                            })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                        }))));
                                        return pr.then(void 0, ((arg_1) => (new FSharpResult$2(1, [new FetchError(3, [arg_1])]))));
                                    }
                                    catch (exn) {
                                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                                    }
                                })())))))).then((_arg_2) => {
                                    const res = _arg_2;
                                    if (res.tag === 1) {
                                        const err = res.fields[0];
                                        return ((err.tag === 2) ? (((err.fields[0].status) === 429) ? ((updateModel(new Model(model.Active, model.ValidComment, model.Processing, model.Published, "You have been temporarily muted for submitting too many posts and/or replies over a short amount of time.")), Promise.resolve())) : ((updateModel(new Model(model.Active, model.ValidComment, model.Processing, model.Published, "Could not publish post. Please try again later.")), Promise.resolve()))) : ((updateModel(new Model(model.Active, model.ValidComment, model.Processing, model.Published, "Could not publish post. Please try again later.")), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                            setProcessing(false);
                                            return Promise.resolve();
                                        }));
                                    }
                                    else {
                                        onPublish(res.fields[0]);
                                        updateModel(new Model(model.Active, model.ValidComment, model.Processing, true, model.ErrorMsg));
                                        return Promise.resolve();
                                    }
                                });
                            }
                        }));
                        pr_1.then();
                    }
                },
                children: "Publiser",
            }))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), snackError(model.ErrorMsg, () => {
                updateModel(new Model(model.Active, model.ValidComment, model.Processing, model.Published, undefined));
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }
    }
    else {
        const children = singleton_1(createElement("button", {
            onClick: (_arg_6) => {
                updateModel(new Model(true, model.ValidComment, model.Processing, model.Published, model.ErrorMsg));
            },
            className: "pill-button",
            children: "Nytt innlegg",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

