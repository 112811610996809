import { createElement } from "react";
import React from "react";
import { createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton, ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { StyleImports_createTheme_argsArray } from "./Feliz.MaterialUI/Styles.js";
import { fromFlatEntries } from "./Feliz.MaterialUI/Flatten.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { Page_FromUrl_7F866359 } from "./Pages.js";
import { RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { ThemeProvider } from "@mui/material";
import { singleton as singleton_1, empty, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Disclaimer } from "./Disclaimer.js";
import { Asset } from "./Asset/Asset.js";
import { MarketList } from "./MarketList.js";
import { Market } from "./Market.js";
import { InsiderTrades } from "./Asset/InsiderTrades.js";
import { ShortOverview } from "./Asset/Short.js";
import { User } from "./User/User.js";
import { PostView } from "./Content/PostView.js";
import { unwrap } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { Search } from "./Search.js";
import { Notifications } from "./Notifications.js";
import { Home } from "./Home.js";
import { LandingPage } from "./Landing.js";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createRoot } from "react-dom/client";
import "../assets/style.scss";


export function drawNotFound(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        minHeight: 80 + "vh",
        alignItems: "center",
        justifyContent: "center",
    }], (elems = [createElement("span", {
        style: {
            fontSize: 2.1 + "rem",
            display: "block",
            marginBottom: 20,
        },
        children: "Sorry! Could not find the page you are looking for.",
    }), createElement("p", {
        children: ["Confirm that the url you have entered is correct."],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export const theme = StyleImports_createTheme_argsArray(fromFlatEntries(singleton(["palette.primary.main", "#73ab84"])));

export function App() {
    let elements, children;
    const location = useFeliz_React__React_useState_Static_1505(Page_FromUrl_7F866359(RouterModule_urlSegments(window.location.pathname + window.location.search, 2)))[0];
    const props_3 = [["clientId", "768029527019-r3c69btl1u386u0d709ahcl830e4v67k.apps.googleusercontent.com"], ["children", singleton(MuiHelpers_createElement(ThemeProvider, [["theme", theme], (elements = [(children = toList(delay(() => {
        let matchValue;
        return append((matchValue = location, (matchValue.tag === 0) ? (empty()) : ((matchValue.tag === 8) ? (empty()) : singleton_1(createElement(Disclaimer, null)))), delay(() => {
            const matchValue_1 = location;
            switch (matchValue_1.tag) {
                case 1:
                    return singleton_1(createElement(Asset, {
                        market: matchValue_1.fields[0],
                        ticker: matchValue_1.fields[1],
                    }));
                case 2:
                    return singleton_1(createElement(MarketList, null));
                case 3:
                    return singleton_1(createElement(Market, {
                        ticker: matchValue_1.fields[0],
                    }));
                case 4:
                    return singleton_1(createElement(InsiderTrades, null));
                case 5:
                    return singleton_1(createElement(ShortOverview, null));
                case 6:
                    return singleton_1(createElement(User, {
                        username: matchValue_1.fields[0],
                    }));
                case 7:
                    return singleton_1(createElement(PostView, {
                        post: unwrap(matchValue_1.fields[0]),
                    }));
                case 8:
                    return singleton_1(createElement(Search, null));
                case 9:
                    return singleton_1(createElement(Notifications, null));
                default:
                    return (isAuthenticated) ? singleton_1(createElement(Home, null)) : singleton_1(createElement(LandingPage, null));
            }
        }));
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]))]];
    return Interop_reactApi_1.createElement(GoogleOAuthProvider, createObj(props_3));
}

export const root = createRoot(document.getElementById("investfora-app"));

root.render(createElement(App, null));

