import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { union_type, option_type, record_type, int32_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { tail, head, isEmpty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Route_$007CInt$007C_$007C, Route_$007CQuery$007C_$007C } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";

export class PostProps extends Record {
    constructor(Src, SrcId) {
        super();
        this.Src = Src;
        this.SrcId = (SrcId | 0);
    }
}

export function PostProps_$reflection() {
    return record_type("Investfora.Pages.PostProps", [], PostProps, () => [["Src", string_type], ["SrcId", int32_type]]);
}

export function PostProps_FromParams(src, sid) {
    let matchResult, sid_1, src_1;
    if (src != null) {
        if (sid != null) {
            matchResult = 0;
            sid_1 = sid;
            src_1 = src;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return new PostProps(src_1, sid_1);
        default:
            return undefined;
    }
}

export class Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Home", "Stock", "Markets", "Market", "InsiderTrade", "Short", "User", "Post", "Search", "Notifications", "NotFound"];
    }
}

export function Page_$reflection() {
    return union_type("Investfora.Pages.Page", [], Page, () => [[], [["Item1", string_type], ["Item2", string_type]], [], [["Item", string_type]], [], [], [["Item", string_type]], [["Item", option_type(PostProps_$reflection())]], [], [], []]);
}

export function Page_FromUrl_7F866359(x) {
    let matchResult, ticker, market, ticker_1, username, sid, src;
    if (!isEmpty(x)) {
        switch (head(x)) {
            case "": {
                if (isEmpty(tail(x))) {
                    matchResult = 0;
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            case "market": {
                if (!isEmpty(tail(x))) {
                    if (isEmpty(tail(tail(x)))) {
                        matchResult = 1;
                        ticker = head(tail(x));
                    }
                    else {
                        matchResult = 7;
                    }
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            case "stock": {
                if (!isEmpty(tail(x))) {
                    if (!isEmpty(tail(tail(x)))) {
                        if (isEmpty(tail(tail(tail(x))))) {
                            matchResult = 2;
                            market = head(tail(x));
                            ticker_1 = head(tail(tail(x)));
                        }
                        else {
                            matchResult = 7;
                        }
                    }
                    else {
                        matchResult = 7;
                    }
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            case "insider-trades": {
                if (isEmpty(tail(x))) {
                    matchResult = 3;
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            case "short": {
                if (isEmpty(tail(x))) {
                    matchResult = 4;
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            case "user": {
                if (!isEmpty(tail(x))) {
                    if (isEmpty(tail(tail(x)))) {
                        matchResult = 5;
                        username = head(tail(x));
                    }
                    else {
                        matchResult = 7;
                    }
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            case "post": {
                if (!isEmpty(tail(x))) {
                    const activePatternResult = Route_$007CQuery$007C_$007C(head(tail(x)));
                    if (activePatternResult != null) {
                        if (!isEmpty(activePatternResult)) {
                            if (head(activePatternResult)[0] === "src") {
                                if (!isEmpty(tail(activePatternResult))) {
                                    if (head(tail(activePatternResult))[0] === "sid") {
                                        const activePatternResult_1 = Route_$007CInt$007C_$007C(head(tail(activePatternResult))[1]);
                                        if (activePatternResult_1 != null) {
                                            if (isEmpty(tail(tail(activePatternResult)))) {
                                                if (isEmpty(tail(tail(x)))) {
                                                    matchResult = 6;
                                                    sid = activePatternResult_1;
                                                    src = head(activePatternResult)[1];
                                                }
                                                else {
                                                    matchResult = 7;
                                                }
                                            }
                                            else {
                                                matchResult = 7;
                                            }
                                        }
                                        else {
                                            matchResult = 7;
                                        }
                                    }
                                    else {
                                        matchResult = 7;
                                    }
                                }
                                else {
                                    matchResult = 7;
                                }
                            }
                            else {
                                matchResult = 7;
                            }
                        }
                        else {
                            matchResult = 7;
                        }
                    }
                    else {
                        matchResult = 7;
                    }
                }
                else {
                    matchResult = 7;
                }
                break;
            }
            default:
                matchResult = 7;
        }
    }
    else {
        matchResult = 7;
    }
    switch (matchResult) {
        case 0:
            return new Page(0, []);
        case 1:
            return new Page(3, [ticker]);
        case 2:
            return new Page(1, [market, ticker_1]);
        case 3:
            return new Page(4, []);
        case 4:
            return new Page(5, []);
        case 5:
            return new Page(6, [username]);
        case 6:
            return new Page(7, [new PostProps(src, sid)]);
        default: {
            let matchResult_1;
            if (!isEmpty(x)) {
                switch (head(x)) {
                    case "post": {
                        if (isEmpty(tail(x))) {
                            matchResult_1 = 0;
                        }
                        else {
                            matchResult_1 = 3;
                        }
                        break;
                    }
                    case "search": {
                        if (isEmpty(tail(x))) {
                            matchResult_1 = 1;
                        }
                        else {
                            matchResult_1 = 3;
                        }
                        break;
                    }
                    case "notifications": {
                        if (isEmpty(tail(x))) {
                            matchResult_1 = 2;
                        }
                        else {
                            matchResult_1 = 3;
                        }
                        break;
                    }
                    default:
                        matchResult_1 = 3;
                }
            }
            else {
                matchResult_1 = 3;
            }
            switch (matchResult_1) {
                case 0:
                    return new Page(7, [undefined]);
                case 1:
                    return new Page(8, []);
                case 2:
                    return new Page(9, []);
                default:
                    return new Page(10, []);
            }
        }
    }
}

export function Page__ToUrl(this$) {
    switch (this$.tag) {
        case 2:
            return "/markets";
        case 3:
            return toText(printf("/market/%s"))(this$.fields[0]);
        case 1:
            return toText(printf("/stock/%s/%s"))(this$.fields[0])(this$.fields[1]);
        case 4:
            return "/insider-trades";
        case 5:
            return "/short";
        case 6:
            return toText(printf("/user/%s"))(this$.fields[0]);
        case 7: {
            const x = this$.fields[0];
            if (x == null) {
                return toText(printf("/post"));
            }
            else {
                const y = x;
                return `/post?src=${y.Src}&sid=${y.SrcId}`;
            }
        }
        case 8:
            return "/search";
        case 9:
            return "/notifications";
        case 10:
            return "/";
        default:
            return "/";
    }
}

