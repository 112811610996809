import { toString as toString_1, Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { DataServer_InsiderTrade_InsiderTradeEvent_$reflection, Analytics_ReviewEntry_$reflection } from "./Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, option_type, array_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { DataServerBaseUrl } from "./Session.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { assetListEntryLoading, assetListHolder } from "./ViewHelpers.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { map as map_1 } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { StockRating__ToTextColorCode, StockRating__ToColorCode, StockRating_FromInt_Z524259A4 } from "./Shared/Shared.js";
import { toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { Feed } from "./Feed.js";
import { DefaultBorder } from "./Design.js";
import { SquareAd } from "./Content/Ads.js";

class Model extends Record {
    constructor(LatestReviews, LatestInsiderTrades, ErrorMsg) {
        super();
        this.LatestReviews = LatestReviews;
        this.LatestInsiderTrades = LatestInsiderTrades;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Home.Model", [], Model, () => [["LatestReviews", option_type(array_type(Analytics_ReviewEntry_$reflection()))], ["LatestInsiderTrades", option_type(array_type(DataServer_InsiderTrade_InsiderTradeEvent_$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LatestReviewsResponse", "LatestInsiderTradesResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Home.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Analytics_ReviewEntry_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Analytics_ReviewEntry_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_InsiderTrade_InsiderTradeEvent_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_InsiderTrade_InsiderTradeEvent_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model(undefined, undefined, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                return new Model(model.LatestReviews, res_1.fields[0], model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 2:
            return new Model(model.LatestReviews, model.LatestInsiderTrades, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                return new Model(res.fields[0], model.LatestInsiderTrades, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
    }
}

/**
 * Fetches the latest published reviews
 */
export function fetchLatestReviews(callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Analytics_ReviewEntry_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}${"/api/asset/mostrecent"}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Analytics_ReviewEntry_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            callback(_arg_2);
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

/**
 * Fetches the latest registered insider trade entries
 */
export function fetchLatestInsiderTrades(callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeEvent_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/insider-trade/latest`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeEvent_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            callback(_arg_2);
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

export function drawReviews(x$0027, title) {
    return assetListHolder(toList(delay(() => append(singleton(createElement("span", {
        style: {
            color: "var(--text-dim)",
            fontStyle: "italic",
            paddingLeft: 7,
        },
        children: title,
    })), delay(() => {
        let xs_5;
        const matchValue = x$0027;
        return (matchValue != null) ? singleton((xs_5 = map_1((y) => {
            let elems_1, elems;
            const rating = StockRating_FromInt_Z524259A4(y.Rating);
            const link = (y.AssetType === 2) ? (`/crypto/${y.AssetTicker}`) : toText(`/stock/${y.MarketTicker}/${y.AssetTicker}`);
            const ticker = (y.AssetType === 2) ? y.AssetTicker : (`${y.AssetTicker}.${y.MarketTicker}`);
            return createElement("div", createObj(ofArray([["className", "preview-listing-holder"], ["style", {
                margin: 5,
            }], (elems_1 = [createElement("div", {
                className: "review-listing-rating",
                style: {
                    backgroundColor: StockRating__ToColorCode(rating),
                    color: StockRating__ToTextColorCode(rating),
                },
                children: createElement("span", {
                    children: [toString_1(rating)],
                }),
            }), createElement("div", createObj(ofArray([["className", "preview-listing-name"], (elems = [createElement("a", {
                href: link,
                children: ticker,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }, matchValue), react.createElement(react.Fragment, {}, ...xs_5))) : append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => singleton(assetListEntryLoading())))))))));
    })))));
}

export function Home() {
    let elems_2, elems_1, elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchLatestInsiderTrades((arg) => {
            dispatch(new Message(1, [arg]));
        });
        fetchLatestReviews((arg_1) => {
            dispatch(new Message(0, [arg_1]));
        });
    });
    return createElement("div", createObj(ofArray([["className", "home-holder"], (elems_2 = [createElement(Feed, null), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 100 + "%",
        maxWidth: 450,
        gap: (((1 + "vw") + " ") + 0) + "px",
    }], (elems_1 = [createElement("div", createObj(ofArray([["style", createObj(ofArray([["display", "flex"], ["flexDirection", "column"], ["alignItems", "center"], ["width", 100 + "%"], ["maxWidth", 450], ["backgroundColor", "#FFFFFF"], DefaultBorder, ["borderRadius", 5]]))], (elems = [drawReviews(patternInput[0].LatestReviews, "Siste analyser fra brukere")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
        style: {
            width: 100 + "%",
        },
        children: createElement(SquareAd, null),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

