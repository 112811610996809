import { Union, Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { DataServer_Short_ShortEvent_$reflection, DataServer_Short_ShortPosition_$reflection, DataServer_Short_LargestShortEntry_$reflection, DataServer_Short_TopShortEntry_$reflection, DataServer_Short_ShareActivityDate_$reflection } from "../Shared/ApiDataTypes.js";
import { int32_type, obj_type, union_type, record_type, string_type, option_type, array_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { DataServerBaseUrl } from "../Session.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { comparePrimitives, createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { Dimensions_TextExtraSmall, Dimensions_TextSmall, DefaultBorder } from "../Design.js";
import { sortByDescending, map as map_1 } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, ComposedChart, Bar, Tooltip, YAxis, XAxis } from "recharts";
import { shortPercentageToString, utcDateToLocalDate, toShortAmountL, utcDateToLocalDayAndMonth } from "../Utils.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { Skeleton } from "@mui/material";
import { assetListEntryLoading, wrapTooltip } from "../ViewHelpers.js";
import { fromFloat32, toInt64 } from "../fable_modules/fable-library-js.4.17.0/BigInt.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";

class Model extends Record {
    constructor(DailyChanges, HighestPercentage, HighestValuation, LargestPositions, LatestShort, ErrorMsg) {
        super();
        this.DailyChanges = DailyChanges;
        this.HighestPercentage = HighestPercentage;
        this.HighestValuation = HighestValuation;
        this.LargestPositions = LargestPositions;
        this.LatestShort = LatestShort;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Short.Model", [], Model, () => [["DailyChanges", option_type(array_type(DataServer_Short_ShareActivityDate_$reflection()))], ["HighestPercentage", option_type(array_type(DataServer_Short_TopShortEntry_$reflection()))], ["HighestValuation", option_type(array_type(DataServer_Short_LargestShortEntry_$reflection()))], ["LargestPositions", option_type(array_type(DataServer_Short_ShortPosition_$reflection()))], ["LatestShort", option_type(array_type(DataServer_Short_ShortEvent_$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DailyChangesResponse", "HighestPercentageResponse", "HighestValuationResponse", "LargestPositionsResponse", "LatestResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Short.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_Short_ShareActivityDate_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_Short_ShareActivityDate_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_Short_TopShortEntry_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_Short_TopShortEntry_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_Short_LargestShortEntry_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_Short_LargestShortEntry_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_Short_ShortPosition_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_Short_ShortPosition_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_Short_ShortEvent_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_Short_ShortEvent_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model(undefined, undefined, undefined, undefined, undefined, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return model;
            }
            else {
                return new Model(model.DailyChanges, res_1.fields[0], model.HighestValuation, model.LargestPositions, model.LatestShort, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.DailyChanges, model.HighestPercentage, res_2.fields[0], model.LargestPositions, model.LatestShort, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return model;
            }
            else {
                return new Model(model.DailyChanges, model.HighestPercentage, model.HighestValuation, res_3.fields[0], model.LatestShort, model.ErrorMsg);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return model;
            }
            else {
                return new Model(model.DailyChanges, model.HighestPercentage, model.HighestValuation, model.LargestPositions, res_4.fields[0], model.ErrorMsg);
            }
        }
        case 5:
            return new Model(model.DailyChanges, model.HighestPercentage, model.HighestValuation, model.LargestPositions, model.LatestShort, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else {
                return new Model(res.fields[0], model.HighestPercentage, model.HighestValuation, model.LargestPositions, model.LatestShort, model.ErrorMsg);
            }
        }
    }
}

function fetchDailyChanges(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_ShareActivityDate_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/daily-changes`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_ShareActivityDate_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchHighestPercentage(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_TopShortEntry_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/highest-short?num=8`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_TopShortEntry_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchHighestValuation(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_LargestShortEntry_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/highest-by-value?num=8`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_LargestShortEntry_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchLargestPositions(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_ShortPosition_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/largest-positions?num=8`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_ShortPosition_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(3, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchLatest(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_ShortEvent_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/latest-short`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_ShortEvent_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(4, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

export function sectionTitle(x) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        borderBottom: (((3 + "px ") + "solid") + " ") + "var(--bg-normal)",
        flexGrow: 1,
        maxWidth: 400,
        marginBottom: 2 + "vh",
    }], (elems = [createElement("span", {
        style: {
            fontWeight: 500,
            margin: 0,
            userSelect: "none",
        },
        children: x,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function section(maxWidth, children) {
    return createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["flexDirection", "column"]), delay(() => append(singleton(["backgroundColor", "#FFFFFF"]), delay(() => append(singleton(["padding", 1 + "vw"]), delay(() => append(singleton(["borderRadius", 5]), delay(() => append(singleton(DefaultBorder), delay(() => {
            const matchValue = maxWidth;
            if (matchValue != null) {
                return singleton(["maxWidth", matchValue]);
            }
            else {
                return empty_1();
            }
        }))))))))))))))),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function shortInfo() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        marginTop: 10,
        fontSize: Dimensions_TextSmall,
        fontStyle: "italic",
    }], (elems = [createElement("span", {
        children: ["Basert på short-posisjoner større eller lik 0.5% av utestående aksjer"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

class DataPoint extends Record {
    constructor(Dato, Opp, Ned) {
        super();
        this.Dato = Dato;
        this.Opp = (Opp | 0);
        this.Ned = (Ned | 0);
    }
}

function DataPoint_$reflection() {
    return record_type("Investfora.Short.DataPoint", [], DataPoint, () => [["Dato", string_type], ["Opp", int32_type], ["Ned", int32_type]]);
}

function drawDailyChanges(x) {
    const legend = (color, txt) => {
        let elems;
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            gap: ((5 + "px ") + 5) + "px",
            fontSize: Dimensions_TextSmall,
            userSelect: "none",
        }], (elems = [createElement("div", {
            style: {
                width: 15,
                height: 15,
                backgroundColor: color,
            },
        }), createElement("span", {
            children: [txt],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    return section(undefined, toList(delay(() => append(singleton(sectionTitle("Endring i posisjoner per dag siste 60 dager")), delay(() => {
        let properties_11, properties_10, elements;
        const matchValue = x;
        return (matchValue != null) ? append(singleton((properties_11 = ofArray([["minWidth", 250], ["minHeight", 210], ["children", (properties_10 = ofArray([["width", 450], ["height", 300], ["data", map_1((d) => (new DataPoint(d.Date, d.Raises, d.Covers)), matchValue)], ["margin", {
            top: defaultArg(10, 0),
            right: defaultArg(10, 0),
            left: defaultArg(0, 0),
            bottom: defaultArg(10, 0),
        }], (elements = ofArray([Interop_reactApi_1.createElement(XAxis, {
            dataKey: "Dato",
            tickFormatter: (t) => (`${utcDateToLocalDayAndMonth(t)}`),
        }), Interop_reactApi_1.createElement(YAxis, {}), Interop_reactApi_1.createElement(Tooltip, {}), Interop_reactApi_1.createElement(Bar, {
            dataKey: "Opp",
            fill: "#36cc08",
            stackId: "a",
        }), Interop_reactApi_1.createElement(Bar, {
            dataKey: "Ned",
            fill: "#cc3608",
            stackId: "a",
        })]), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), Interop_reactApi_1.createElement(ComposedChart, createObj(properties_10)))]]), Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_11)))), delay(() => {
            let elems_2;
            return append(singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexWrap: "wrap",
                gap: ((20 + "px ") + 20) + "px",
                justifyContent: "center",
            }], (elems_2 = [legend("#36cc08", "Antall nye/økte short-posisjoner"), legend("#cc3608", "Antall reduserte/inndekkede short-posisjoner")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => singleton(shortInfo())));
        })) : singleton(MuiHelpers_createElement(Skeleton, [["variant", "rectangular"], ["animation", "wave"], ["style", {
            minHeight: 210,
            width: 100 + "%",
            minWidth: 250,
        }]]));
    })))));
}

export function td$0027(children) {
    return createElement("td", {
        style: {
            padding: 5,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function assetNameColumn(ticker, market, name) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        whiteSpace: "nowrap",
        userSelect: "none",
        maxWidth: 120,
        overflowX: "hidden",
        textOverflow: "ellipsis",
    }], (elems_1 = [createElement("a", createObj(ofArray([["href", `/stock/${market}/${ticker}`], (elems = [wrapTooltip(name, createElement("span", {
        style: {
            fontWeight: "bold",
        },
        children: ticker,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", {
        style: {
            fontSize: Dimensions_TextExtraSmall,
        },
        children: name,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function valuationColumn(lastPrice, numShares, lastPriceDate) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        userSelect: "none",
        whiteSpace: "nowrap",
    }], (elems = [createElement("span", {
        style: {
            fontWeight: "bold",
        },
        children: `${toShortAmountL(toInt64(fromFloat32(lastPrice * numShares)))}`,
    }), createElement("span", {
        style: {
            fontSize: Dimensions_TextExtraSmall,
        },
        children: `Per sluttkurs ${utcDateToLocalDate(lastPriceDate)}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function dateShortColumn(shortDate) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        userSelect: "none",
    }], (elems = [createElement("span", {
        style: {
            fontWeight: "bold",
        },
        children: `${utcDateToLocalDate(shortDate)}`,
    }), createElement("span", {
        style: {
            fontSize: Dimensions_TextExtraSmall,
        },
        children: "Dato short",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function drawHighestShort(x) {
    return section(undefined, toList(delay(() => append(singleton(sectionTitle("Størst andel short")), delay(() => {
        let matchValue, children_4, children_2;
        return append((matchValue = x, (matchValue != null) ? singleton((children_4 = singleton_1((children_2 = map_1((z_1) => {
            let elems;
            const children = ofArray([td$0027([assetNameColumn(z_1.Ticker, z_1.Market, z_1.Name)]), td$0027([createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                userSelect: "none",
            }], (elems = [createElement("span", {
                style: {
                    fontWeight: "bold",
                },
                children: shortPercentageToString(z_1.Percentage),
            }), createElement("span", {
                style: {
                    fontSize: Dimensions_TextExtraSmall,
                },
                children: "Av utestående",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]), td$0027([dateShortColumn(z_1.ShortDate)])]);
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }, sortByDescending((z) => z.Percentage, matchValue, {
            Compare: comparePrimitives,
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))), createElement("table", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))) : append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => singleton(assetListEntryLoading())))))))), delay(() => singleton(shortInfo())));
    })))));
}

function drawLargestValue(x) {
    return section(undefined, toList(delay(() => append(singleton(sectionTitle("Største markedsverdi av short")), delay(() => {
        let matchValue, td$0027_1, children_5, children_3;
        return append((matchValue = x, (matchValue != null) ? ((td$0027_1 = ((children) => createElement("td", {
            style: {
                padding: 5,
            },
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), singleton((children_5 = singleton_1((children_3 = map_1((z_1) => {
            const children_1 = ofArray([td$0027_1([assetNameColumn(z_1.Ticker, z_1.Market, z_1.Name)]), td$0027_1([valuationColumn(z_1.LastPrice, z_1.NumShares, z_1.LastPriceDate)])]);
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            });
        }, sortByDescending((z) => (z.LastPrice * z.NumShares), matchValue, {
            Compare: comparePrimitives,
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
        }))), createElement("table", {
            children: Interop_reactApi.Children.toArray(Array.from(children_5)),
        }))))) : append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => singleton(assetListEntryLoading())))))))), delay(() => singleton(shortInfo())));
    })))));
}

function drawLargestPositions(x) {
    return section(undefined, toList(delay(() => append(singleton(sectionTitle("Største posisjoner")), delay(() => {
        let matchValue, y, elems_2, children_4, children_2;
        return append((matchValue = x, (matchValue != null) ? ((y = matchValue, singleton(createElement("div", createObj(ofArray([["style", {
            overflowX: "auto",
            maxWidth: 91.5 + "vw",
        }], (elems_2 = [(children_4 = singleton_1((children_2 = map_1((z_1) => {
            let elems_1, elems;
            const children = ofArray([td$0027([valuationColumn(z_1.LastClose, z_1.NumShares, z_1.LastCloseDate)]), td$0027([assetNameColumn(z_1.Ticker, z_1.Market, z_1.Name)]), td$0027([createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                whiteSpace: "nowrap",
                userSelect: "none",
            }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
                maxWidth: 250,
                overflowX: "hidden",
                textOverflow: "ellipsis",
            }], (elems = [wrapTooltip(z_1.Institution, createElement("span", {
                style: {
                    fontWeight: "bold",
                },
                children: z_1.Institution,
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", {
                style: {
                    fontSize: Dimensions_TextExtraSmall,
                },
                children: `${z_1.NumShares} aksjer (${shortPercentageToString(z_1.Percent)})`,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))])]);
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }, sortByDescending((z) => (z.LastClose * z.NumShares), y, {
            Compare: comparePrimitives,
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))), createElement("table", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))))) : append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => append(singleton(assetListEntryLoading()), delay(() => singleton(assetListEntryLoading())))))))), delay(() => singleton(shortInfo())));
    })))));
}

export function ShortOverview() {
    let elems_1, elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchDailyChanges(dispatch);
        fetchHighestPercentage(dispatch);
        fetchHighestValuation(dispatch);
        fetchLargestPositions(dispatch);
        fetchLatest(dispatch);
    });
    return createElement("div", createObj(ofArray([["className", "default-gap"], ["style", {
        display: "flex",
        flexDirection: "column",
        padding: 1 + "vw",
    }], (elems_1 = [drawDailyChanges(model_1.DailyChanges), createElement("div", createObj(ofArray([["className", "default-gap"], ["style", {
        display: "flex",
        flexWrap: "wrap",
    }], (elems = [drawHighestShort(model_1.HighestPercentage), drawLargestValue(model_1.HighestValuation), drawLargestPositions(model_1.LargestPositions)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

