import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, bool_type, string_type, option_type, array_type, union_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { empty as empty_1, append as append_2, singleton, delay, toList, fold } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { unwrap, map as map_1, defaultArg, toArray } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PostForm, Target as Target_1 } from "./PostForm.js";
import { Post_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { mapIndexed, map, sort, tryHead, sortByDescending, append } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { parseAsUtc } from "../Utils.js";
import { Array_distinctBy } from "../fable_modules/fable-library-js.4.17.0/Seq2.js";
import { equals, createObj, uncurry2, comparePrimitives, stringHash } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { compare } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, append as append_1, cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useCallback_1CA17B65, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { ReviewForm } from "../Asset/ReviewForm.js";
import { postLoadingIndicator, snackError } from "../ViewHelpers.js";
import { post } from "./Post.js";
import { FeedEntry } from "./Ads.js";

export class Target extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Asset", "AssetPost", "Review", "AssetShort", "InsiderTrade", "InsiderTrades"];
    }
}

export function Target_$reflection() {
    return union_type("Investfora.PostFeed.Target", [], Target, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], []]);
}

/**
 * Builds to request path for the feed target
 */
export function Target__ToRequestPath(this$, lastReviewId, lastPostId, lastInsiderTradeId, seDt, seSkip) {
    switch (this$.tag) {
        case 1:
            return `/api/asset/post/feed/${this$.fields[0]}${fold((_arg_5, x_7) => (`?pid=${x_7}`), "", toArray(lastPostId))}`;
        case 2:
            return `/api/review/asset/${this$.fields[0]}${fold((_arg_6, x_9) => (`&rid=${x_9}`), "", toArray(lastReviewId))}`;
        case 3:
            return `/api/asset/short/feed/${this$.fields[0]}?${fold((_arg_7, x_11) => (`&sedt=${x_11}`), "", toArray(seDt))}${fold((_arg_8, x_12) => (`&se_skip=${x_12}`), "", toArray(seSkip))}`;
        case 4:
            return `/api/asset/insider-trade/feed/${this$.fields[0]}${fold((_arg_9, x_14) => (`?itid=${x_14}`), "", toArray(lastInsiderTradeId))}`;
        case 5:
            return `/api/asset/insider-trade/latest${fold((_arg_10, x_15) => (`?itid=${x_15}`), "", toArray(lastInsiderTradeId))}`;
        default: {
            const r = fold((_arg, x_1) => (`&rid=${x_1}`), "", toArray(lastReviewId));
            const p = fold((_arg_1, x_2) => (`&pid=${x_2}`), "", toArray(lastPostId));
            const it = fold((_arg_2, x_3) => (`&itid=${x_3}`), "", toArray(lastInsiderTradeId));
            const seDt$0027 = fold((_arg_3, x_4) => (`&sedt=${x_4}`), "", toArray(seDt));
            const se = fold((_arg_4, x_5) => (`&se_skip=${x_5}`), "", toArray(seSkip));
            return `/api/asset/feed/${this$.fields[0]}${(((((r + p) + it) + seDt$0027) + se).length > 0) ? (`?${r}${p}${it}${seDt$0027}${se}`) : ""}`;
        }
    }
}

export function Target__ToPostFormTarget(this$) {
    switch (this$.tag) {
        case 1:
            return new Target_1(0, [this$.fields[0]]);
        case 2:
            return new Target_1(0, [this$.fields[0]]);
        case 3:
            return new Target_1(0, [this$.fields[0]]);
        case 4:
            return new Target_1(0, [this$.fields[0]]);
        case 5:
            return new Target_1(1, []);
        default:
            return new Target_1(0, [this$.fields[0]]);
    }
}

export function Target__EmptyText(this$) {
    switch (this$.tag) {
        case 1:
            return "Ingen innlegg enda.";
        case 2:
            return "Ingen analyser enda.";
        case 3:
            return "Ingen shortposisjoner registrert enda.";
        case 4:
        case 5:
            return "Ingen innsidehandel registrert enda.";
        default:
            return "Ingen analyser eller innlegg enda.";
    }
}

export function Target__ToSignInText(this$) {
    switch (this$.tag) {
        case 2:
            return "for å publisere en analyse";
        case 1:
            return "for å publisere ett innlegg";
        case 3:
            return "for å få samle short oppdateringer fra dine favorittaksjer i feeden din";
        case 4:
        case 5:
            return "for å få innsidehandeler fra dine favorittaksjer i feeden din.";
        default:
            return "for å publisere en analyse eller ett innlegg";
    }
}

class Model extends Record {
    constructor(Target, Posts, ErrorMsg, LastRevId, LastId, LastInsiderId, LastShortEventDate, ShortSkips, More) {
        super();
        this.Target = Target;
        this.Posts = Posts;
        this.ErrorMsg = ErrorMsg;
        this.LastRevId = LastRevId;
        this.LastId = LastId;
        this.LastInsiderId = LastInsiderId;
        this.LastShortEventDate = LastShortEventDate;
        this.ShortSkips = ShortSkips;
        this.More = More;
    }
}

function Model_$reflection() {
    return record_type("Investfora.PostFeed.Model", [], Model, () => [["Target", Target_$reflection()], ["Posts", option_type(array_type(Post_$reflection()))], ["ErrorMsg", option_type(string_type)], ["LastRevId", option_type(int32_type)], ["LastId", option_type(int32_type)], ["LastInsiderId", option_type(int32_type)], ["LastShortEventDate", option_type(string_type)], ["ShortSkips", option_type(int32_type)], ["More", bool_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PostsResponse", "AppendPost", "RemovePost", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.PostFeed.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Post_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Post_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", Post_$reflection()]], [["Item", int32_type]], []]);
}

function init(t) {
    return new Model(t, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
}

function update(model, msg) {
    let matchValue_6, matchValue_7, array_21, matchValue, lse, array_20, array_19;
    switch (msg.tag) {
        case 1: {
            const x_7 = msg.fields[0];
            return new Model(model.Target, (matchValue_6 = model.Posts, (matchValue_6 != null) ? append([x_7], matchValue_6) : [x_7]), model.ErrorMsg, model.LastRevId, model.LastId, model.LastInsiderId, model.LastShortEventDate, model.ShortSkips, model.More);
        }
        case 2:
            return new Model(model.Target, (matchValue_7 = model.Posts, (matchValue_7 == null) ? undefined : ((array_21 = matchValue_7, array_21.filter((a) => (a.Id !== msg.fields[0]))))), model.ErrorMsg, model.LastRevId, model.LastId, model.LastInsiderId, model.LastShortEventDate, model.ShortSkips, model.More);
        case 3:
            return new Model(model.Target, model.Posts, undefined, model.LastRevId, model.LastId, model.LastInsiderId, model.LastShortEventDate, model.ShortSkips, model.More);
        default: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                const x = res.fields[0];
                const y_2 = sortByDescending((z_1) => parseAsUtc(z_1.Published), Array_distinctBy((z) => (`${z.Type}-${z.Id}`), (matchValue = model.Posts, (matchValue != null) ? append(matchValue, x) : x), {
                    Equals: (x_1, y) => (x_1 === y),
                    GetHashCode: stringHash,
                }), {
                    Compare: compare,
                });
                let lastId;
                const lowest = tryHead(sort(map((y_4) => y_4.Id, y_2.filter((y_3) => (y_3.Type === "asset-post")), Int32Array), {
                    Compare: comparePrimitives,
                }));
                const matchValue_1 = model.LastId;
                if (matchValue_1 != null) {
                    const l = matchValue_1 | 0;
                    lastId = fold((_arg, z_2) => {
                        if (z_2 < l) {
                            return z_2 | 0;
                        }
                        else {
                            return l | 0;
                        }
                    }, l, toArray(lowest));
                }
                else {
                    lastId = lowest;
                }
                let lastRevId;
                if (model.Target.tag === 0) {
                    const lowest_1 = tryHead(sort(map((y_7) => y_7.Id, y_2.filter((y_6) => (y_6.Type === "review")), Int32Array), {
                        Compare: comparePrimitives,
                    }));
                    const matchValue_3 = model.LastId;
                    if (matchValue_3 != null) {
                        const l_1 = matchValue_3 | 0;
                        lastRevId = fold((_arg_1, z_3) => {
                            if (z_3 < l_1) {
                                return z_3 | 0;
                            }
                            else {
                                return l_1 | 0;
                            }
                        }, l_1, toArray(lowest_1));
                    }
                    else {
                        lastRevId = lowest_1;
                    }
                }
                else {
                    lastRevId = undefined;
                }
                let lastInsiderId;
                const lowest_2 = tryHead(sort(map((y_10) => y_10.Id, y_2.filter((y_9) => (y_9.Type === "insider-trade")), Int32Array), {
                    Compare: comparePrimitives,
                }));
                const matchValue_4 = model.LastInsiderId;
                if (matchValue_4 != null) {
                    const l_2 = matchValue_4 | 0;
                    lastInsiderId = fold((_arg_2, z_4) => {
                        if (z_4 < l_2) {
                            return z_4 | 0;
                        }
                        else {
                            return l_2 | 0;
                        }
                    }, l_2, toArray(lowest_2));
                }
                else {
                    lastInsiderId = lowest_2;
                }
                let patternInput;
                const lowest_3 = tryHead(sort(map((y_13) => y_13.Published, y_2.filter((y_12) => (y_12.Type === "short-event"))), {
                    Compare: comparePrimitives,
                }));
                const matchValue_5 = model.LastShortEventDate;
                if (matchValue_5 != null) {
                    const l_3 = matchValue_5;
                    const newLowest = fold((_arg_3, z_5) => {
                        if (z_5 < l_3) {
                            return z_5;
                        }
                        else {
                            return l_3;
                        }
                    }, l_3, toArray(lowest_3));
                    patternInput = [newLowest, newLowest !== l_3];
                }
                else {
                    patternInput = [lowest_3, false];
                }
                const lastShortEventDate = patternInput[0];
                return new Model(model.Target, y_2, model.ErrorMsg, lastRevId, lastId, lastInsiderId, lastShortEventDate, patternInput[1] ? undefined : ((lastShortEventDate != null) ? ((lse = lastShortEventDate, (array_20 = ((array_19 = y_2.filter((e) => (e.Type === "short-event")), array_19.filter((e_1) => (e_1.Published === lse)))), array_20.length))) : undefined), x.length > 0);
            }
            else {
                return new Model(model.Target, model.Posts, "Kunne ikke laste innhold. Vennligst prøv igjen senere.", model.LastRevId, model.LastId, model.LastInsiderId, model.LastShortEventDate, model.ShortSkips, model.More);
            }
        }
    }
}

function fetchPosts(target, lastRevId, lastId, lastInsiderId, seDt, se, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = Target__ToRequestPath(target, lastRevId, lastId, lastInsiderId, seDt, se);
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Post_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Post_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function noContentMessage(x) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        height: 100 + "%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }], (elems = [createElement("span", {
        style: {
            color: "var(--text-dim)",
        },
        children: (x.tag === 2) ? "Ingen analyser enda." : ((x.tag === 1) ? "Ingen innlegg enda." : ((x.tag === 3) ? "Ingen short-posisjoner registrert enda." : ((x.tag === 4) ? "Ingen innsidehandler registrert enda." : ((x.tag === 5) ? "Ingen innsidehandler registrert enda." : "Ingen analyser, innlegg eller short-endringer enda.")))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

class ActiveForm extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoActive", "ReviewActive", "PostActive"];
    }
}

function ActiveForm_$reflection() {
    return union_type("Investfora.PostFeed.ActiveForm", [], ActiveForm, () => [[], [], []]);
}

function AssetForms(assetFormsInputProps) {
    let elems_1;
    const onPublish = assetFormsInputProps.onPublish;
    const assetId = assetFormsInputProps.assetId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new ActiveForm(0, []));
    const setActive = patternInput[1];
    const active = patternInput[0];
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        width: 100 + "%",
    }], (elems_1 = toList(delay(() => {
        let elems;
        return (active.tag === 1) ? singleton(createElement(ReviewForm, {
            assetId: assetId,
            autoOpenMode: false,
            onPublished: onPublish,
        })) : ((active.tag === 2) ? singleton(createElement(PostForm, {
            target: new Target_1(0, [assetId]),
            onPublish: onPublish,
            autoActive: true,
            onClose: () => {
                setActive(new ActiveForm(0, []));
            },
        })) : singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }], (elems = [createElement("button", {
            onClick: (_arg) => {
                setActive(new ActiveForm(1, []));
            },
            className: "pill-button",
            children: "Ny analyse",
        }), createElement("span", {
            style: {
                display: "block",
                margin: 8,
                color: "var(--text-dim)",
                fontStyle: "italic",
            },
            children: "eller",
        }), createElement("button", {
            onClick: (_arg_1) => {
                setActive(new ActiveForm(2, []));
            },
            className: "pill-button",
            children: "Nytt innlegg",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function cantPublishInfo(title, desc, children) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1 + "vw",
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
    }], (elems = append_1(ofArray([createElement("span", {
        children: [title],
    }), createElement("span", {
        style: {
            marginTop: 5,
            fontSize: 0.8 + "rem",
            fontStyle: "italic",
            maxWidth: 500,
        },
        children: desc,
    })]), children), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function loginOrRegister(model) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["style", {
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        backgroundColor: "#FFFFFF",
        margin: ((10 + "px ") + 0) + "px",
        maxWidth: 778,
        width: 100 + "%",
    }], (elems_1 = [createElement("span", createObj(ofArray([["style", {
        display: "block",
        margin: 1 + "vw",
    }], (elems = [createElement("a", {
        style: {
            textDecoration: "underline",
        },
        href: "/sign-in",
        children: "Logg inn",
    }), " eller ", createElement("a", {
        style: {
            textDecoration: "underline",
        },
        href: "/sign-up",
        children: "registrer deg",
    }), ` ${Target__ToSignInText(model.Target)}`], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function view(model, dispatch, includeAds) {
    let elems_5;
    const onPublish = useReact_useCallback_1CA17B65((arg) => {
        dispatch(new Message(1, [arg]));
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: (((1 + "vw") + " ") + 0) + "px",
    }], (elems_5 = toList(delay(() => append_2(singleton(snackError(model.ErrorMsg, () => {
        dispatch(new Message(3, []));
    })), delay(() => {
        let elems_1, elems_2;
        const matchValue = model.Posts;
        if (matchValue != null) {
            const x = matchValue;
            return append_2((isAuthenticated) ? singleton(createElement("div", createObj(ofArray([["style", {
                marginBottom: 10 + "px",
                textAlign: "center",
                width: 100 + "%",
            }], (elems_1 = toList(delay(() => {
                let elems;
                return !(emailVerified) ? singleton(cantPublishInfo("Bekreft e-post adressen din før du kan publisere", "Du mottok en e-post med instruksjoner for å bekrefte din e-post adresse når du registrerte deg på Aksje.io. Du kan sende en ny bekreftelses e-post fra din kontoside.", singleton_1(createElement("a", {
                    style: {
                        fontSize: 0.8 + "rem",
                    },
                    href: "/account",
                    children: "Til kontosiden",
                })))) : singleton(createElement("div", createObj(ofArray([["key", "publish-forms"], ["style", {
                    display: "flex",
                    justifyContent: "center",
                }], (elems = toList(delay(() => {
                    const matchValue_1 = model.Target;
                    switch (matchValue_1.tag) {
                        case 2:
                            return singleton(createElement(ReviewForm, {
                                assetId: matchValue_1.fields[0],
                                autoOpenMode: model.Target.tag === 2,
                                onPublished: onPublish,
                            }));
                        case 1:
                            return singleton(createElement(PostForm, {
                                target: Target__ToPostFormTarget(model.Target),
                                onPublish: onPublish,
                                autoActive: false,
                                onClose: () => {
                                },
                            }));
                        case 3:
                        case 4:
                        case 5: {
                            return empty_1();
                        }
                        default:
                            return singleton(createElement(AssetForms, {
                                assetId: matchValue_1.fields[0],
                                onPublish: onPublish,
                            }));
                    }
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : singleton(createElement("div", createObj(ofArray([["key", "publish-forms"], ["style", {
                width: 100 + "%",
            }], (elems_2 = toList(delay(() => {
                const matchValue_3 = model.Target;
                switch (matchValue_3.tag) {
                    case 0:
                        return singleton(createElement(ReviewForm, {
                            assetId: matchValue_3.fields[0],
                            autoOpenMode: true,
                            onPublished: onPublish,
                        }));
                    case 2:
                        return singleton(createElement(ReviewForm, {
                            assetId: matchValue_3.fields[0],
                            autoOpenMode: true,
                            onPublished: onPublish,
                        }));
                    default: {
                        return empty_1();
                    }
                }
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => append_2((!equals(model.Target, new Target(5, [])) && !(isAuthenticated)) ? singleton(loginOrRegister(model)) : empty_1(), delay(() => {
                let elems_3;
                return append_2(singleton(createElement("div", createObj(ofArray([["className", "feed"], (elems_3 = mapIndexed((i, y) => {
                    const xs_4 = toList(delay(() => append_2(singleton(post(y, (arg_1) => {
                        dispatch(new Message(2, [arg_1]));
                    })), delay(() => (((((i + 1) % 3) === 0) && includeAds) ? singleton(createElement(FeedEntry, null)) : empty_1())))));
                    return react.createElement(react.Fragment, {}, ...xs_4);
                }, x), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => append_2((x.length === 0) ? singleton(noContentMessage(model.Target)) : empty_1(), delay(() => {
                    let elems_4;
                    return model.More ? singleton(createElement("div", createObj(ofArray([["style", {
                        textAlign: "center",
                    }], (elems_4 = [createElement("button", {
                        className: "pill-button-small",
                        onClick: (_arg_1) => {
                            fetchPosts(model.Target, model.LastRevId, model.LastId, model.LastInsiderId, model.LastShortEventDate, model.ShortSkips, dispatch);
                        },
                        children: "Last mer",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))) : empty_1();
                }))));
            }))));
        }
        else {
            return append_2(singleton(postLoadingIndicator()), delay(() => singleton(postLoadingIndicator())));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function PostFeed(postFeedInputProps) {
    const includeAds = postFeedInputProps.includeAds;
    const target = postFeedInputProps.target;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(target));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchPosts(model_1.Target, model_1.LastRevId, model_1.LastId, model_1.LastInsiderId, model_1.LastShortEventDate, model_1.ShortSkips, dispatch);
    });
    return view(model_1, dispatch, includeAds);
}

