import { Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { createElement } from "react";
import React from "react";
import { Dimensions_TextSmall } from "../Design.js";
import { comparePrimitives, createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { sortByDescending, map } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { utcStringToLocalTimeSpan, utcStringToLocalDaySpan, quoteToString, formatSpacedNumber, toShortAmount } from "../Utils.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";

export class PostType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShortEvent", "InsiderTrade"];
    }
}

export function PostType_$reflection() {
    return union_type("Investfora.SystemPost.PostType", [], PostType, () => [[], []]);
}

function drawShortEventPost(post) {
    let matchValue_1, elems_3, elems_1, elems_2, children_8, children_2, children, children_6, elems;
    const th$0027 = (title) => createElement("th", {
        style: {
            fontSize: Dimensions_TextSmall,
            padding: ((0 + "px ") + 3) + "px",
        },
        children: title,
    });
    const td$0027 = (txt) => createElement("td", {
        style: {
            padding: ((0 + "px ") + 3) + "px",
        },
        children: txt,
    });
    const matchValue = post.ShortInfo;
    if (matchValue != null) {
        const si = matchValue;
        const percentageChange = ((matchValue_1 = si.PrevPercentage, (matchValue_1 != null) ? (si.NewPercentage - matchValue_1) : si.NewPercentage)) / 100;
        const includePlus = (percentageChange > 0) ? "+" : "";
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexWrap: "wrap",
            gap: ((20 + "px ") + 20) + "px",
            padding: ((20 + "px ") + 0) + "px",
        }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            borderRight: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        }], (elems_1 = [createElement("span", {
            style: {
                fontSize: Dimensions_TextSmall,
                userSelect: "none",
            },
            children: "Andel short",
        }), createElement("span", {
            style: {
                fontSize: 2.5 + "rem",
                userSelect: "none",
            },
            children: `${si.NewPercentage / 100}%`,
        }), createElement("span", {
            style: {
                fontSize: Dimensions_TextSmall,
                userSelect: "none",
            },
            children: `${includePlus}${percentageChange} pp. endring`,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflowX: "hidden",
        }], (elems_2 = [(children_8 = ofArray([(children_2 = singleton((children = ofArray([th$0027("Kjøper/selger"), th$0027("Aksjer"), th$0027("Endring")]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_6 = map((p_2) => {
            const change = (p_2.NewNumShares - p_2.PrevNumShares) | 0;
            const includePlus_1 = (change > 0) ? "+" : "";
            const children_4 = ofArray([createElement("td", {
                style: {
                    maxWidth: 200,
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    padding: ((0 + "px ") + 3) + "px",
                },
                children: p_2.Institution,
            }), td$0027(toShortAmount(p_2.NewNumShares)), td$0027(`${includePlus_1}${toShortAmount(change)}`)]);
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            });
        }, sortByDescending((p_1) => (p_1.NewNumShares - p_1.PrevNumShares), si.Positions, {
            Compare: comparePrimitives,
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))]), createElement("table", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
    }
    else {
        return createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("p", {
            children: ["Kunne ikke laste informasjon om shortposisjon. Vennligst prøv igjen senere."],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
}

function drawInsiderTradePost(post) {
    let elems_1, elems_7, elems;
    const matchValue = post.InsiderInfo;
    if (matchValue != null) {
        const ii = matchValue;
        const tradeColor = (ii.NumShares > 0) ? "var(--buy)" : ((ii.NumShares < 0) ? "var(--sell)" : "var(--text-main)");
        const indicator = createElement("div", createObj(ofArray([["className", "pill-button"], ["style", {
            backgroundColor: "#fff",
            color: tradeColor,
            cursor: "auto",
            userSelect: "none",
        }], (elems_1 = [createElement("span", {
            style: {
                fontWeight: "bold",
            },
            children: (ii.NumShares > 0) ? "Kjøpt" : ((ii.NumShares < 0) ? "Solgt" : "Flyttet/annet"),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        const flexColumn$0027 = (x) => createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray(Array.from(x)),
        });
        const infoLabel = (txt) => createElement("span", {
            style: {
                fontSize: Dimensions_TextSmall,
            },
            children: txt,
        });
        const contentText = (txt_1) => createElement("span", {
            style: {
                fontSize: 1.225 + "rem",
                userSelect: "none",
            },
            children: txt_1,
        });
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: ((10 + "px ") + 10) + "px",
            padding: ((20 + "px ") + 0) + "px",
        }], (elems_7 = toList(delay(() => {
            let elems_5, elems_3, elems_4;
            return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexWrap: "wrap",
                flexGrow: 1,
                gap: ((10 + "px ") + 10) + "px",
            }], (elems_5 = [createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: ((20 + "px ") + 0) + "px",
                backgroundColor: tradeColor,
                borderRadius: 5,
                padding: 10,
                width: 100 + "%",
            }], ["className", "insider-trade-indicator"], (elems_3 = [indicator, createElement("span", {
                style: {
                    fontWeight: "bold",
                    fontSize: 1.225 + "rem",
                    color: "#FFFFFF",
                    userSelect: "none",
                },
                children: `${formatSpacedNumber(ii.NumShares)} aksjer`,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: ((10 + "px ") + 0) + "px",
            }], (elems_4 = toList(delay(() => append(singleton_1(flexColumn$0027([infoLabel("Innsider"), contentText(ii.Insider)])), delay(() => {
                const matchValue_1 = ii.NewTotal;
                if (matchValue_1 != null) {
                    const nt = matchValue_1 | 0;
                    return singleton_1(flexColumn$0027([infoLabel("Antall aksjer etter handel"), contentText(formatSpacedNumber(nt))]));
                }
                else {
                    return empty();
                }
            })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                let elems_6;
                const matchValue_2 = ii.AvgPrice;
                if (matchValue_2 != null) {
                    const ap = matchValue_2;
                    const wasExchanged = (ii.ExchangeInfo != null) ? "*" : "";
                    return singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "space-between",
                        gap: ((10 + "px ") + 0) + "px",
                    }], ["className", "insider-trade-avg-price"], (elems_6 = [flexColumn$0027([infoLabel(`Snittpris${wasExchanged}`), contentText(`NOK ${quoteToString(ap)}`)]), flexColumn$0027([infoLabel("Verdi av handel"), contentText(toShortAmount(~~(ap * ii.NumShares)))])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
                }
                else {
                    return empty();
                }
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
    }
    else {
        return createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("p", {
            children: ["Kunne ikke laste informasjon om innsidehandel. Vennligst prøv igjen senere."],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
}

export function Post(postInputProps) {
    let elems_10, elems_1, elems_9, elems_8;
    const post = postInputProps.post;
    const type$0027 = postInputProps["type\'"];
    const compId = postInputProps.compId;
    const eventTime = (type$0027.tag === 1) ? utcStringToLocalDaySpan(post.Published) : utcStringToLocalTimeSpan(post.Published);
    return createElement("div", createObj(ofArray([["className", "post"], ["key", compId], ["style", {
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        borderRadius: 5,
    }], (elems_10 = [createElement("div", createObj(ofArray([["className", "post-header"], (elems_1 = toList(delay(() => {
        let elems;
        const matchValue = post.AssetInfo;
        if (matchValue != null) {
            const si = matchValue;
            return singleton_1(createElement("a", createObj(ofArray([["href", `/stock/${si.Market}/${si.Ticker}`], (elems = [createElement("span", {
                style: {
                    fontSize: 1.225 + "rem",
                },
                children: si.Name,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }
        else {
            return singleton_1(createElement("span", {
                children: (type$0027.tag === 1) ? "Mangler informasjon om innsidehandel. Vennligst prøv igjen senere." : "Mangler short informasjon. Vennligst prøv igjen senere.",
            }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "post-content-holder"], (elems_9 = [createElement("div", createObj(ofArray([["className", "post-content"], (elems_8 = toList(delay(() => {
        let elems_4, elems_2, children;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            borderBottom: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
            fontSize: Dimensions_TextSmall,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: ((0 + "px ") + 20) + "px",
        }], (elems_4 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
            gap: ((0 + "px ") + 20) + "px",
        }], (elems_2 = [createElement("span", {
            style: {
                fontWeight: "bold",
            },
            children: (type$0027.tag === 1) ? "Innsidehandel" : "Shortoppdatering",
        }), createElement("span", {
            children: [eventTime],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), (children = toList(delay(() => {
            let elems_3;
            if (type$0027.tag === 1) {
                const matchValue_1 = post.InsiderInfo;
                if (matchValue_1 != null) {
                    return singleton_1(createElement("a", createObj(ofArray([["href", matchValue_1.SourceUrl], ["target", "_blank"], (elems_3 = [createElement("i", {
                        className: "fas fa-link",
                        style: {
                            marginRight: 5,
                        },
                    }), createElement("span", {
                        children: ["Til børsmelding"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
                }
                else {
                    return empty();
                }
            }
            else {
                return empty();
            }
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => append((type$0027.tag === 1) ? singleton_1(drawInsiderTradePost(post)) : singleton_1(drawShortEventPost(post)), delay(() => {
            let matchValue_4, matchValue_5, ei, elems_6, elems_5;
            return (type$0027.tag === 1) ? append((matchValue_4 = post.InsiderInfo, (matchValue_4 != null) ? ((matchValue_5 = matchValue_4.ExchangeInfo, (matchValue_5 != null) ? ((ei = matchValue_5, singleton_1(createElement("div", createObj(ofArray([["style", {
                fontSize: Dimensions_TextSmall,
                color: "var(--text-dim)",
            }], (elems_6 = [createElement("i", {
                className: "fas fa-info-circle",
                style: {
                    marginRight: 6,
                    marginLeft: 2,
                },
            }), createElement("span", {
                children: [`*Denne handelen ble meldt som ${ei.Currency}${quoteToString(ei.AvgPrice)} og konvertert til NOK. NOK verdi er estimert.`],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))))) : (empty()))) : (empty())), delay(() => {
                let elems_7;
                return singleton_1(createElement("div", createObj(ofArray([["style", {
                    fontSize: Dimensions_TextSmall,
                    color: "var(--text-dim)",
                }], (elems_7 = [createElement("i", {
                    className: "fas fa-robot",
                    style: {
                        marginRight: 5,
                    },
                }), createElement("span", {
                    children: ["Denne oppsummeringen er AI-generert av ChatGPT fra børsmeldingen. Med forbehold om feil."],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))));
            })) : singleton_1(createElement("div", createObj(ofArray([["style", {
                fontSize: Dimensions_TextSmall,
                color: "var(--text-dim)",
            }], (elems_5 = [createElement("span", {
                children: ["Basert på short-posisjoner større eller lik 0.5% av utestående aksjer"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
}

