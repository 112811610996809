import { toString, Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { record_type, class_type, string_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { createObj, defaultOf, equals } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { createElement } from "react";
import React from "react";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { pillButtonSmall, smallIconButton } from "../ViewHelpers.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { DialogActions, DialogContent, TextField, DialogTitle, Dialog } from "@mui/material";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { some } from "../fable_modules/fable-library-js.4.17.0/Option.js";

class LinkForm extends Record {
    constructor(Text$, Link, Target) {
        super();
        this.Text = Text$;
        this.Link = Link;
        this.Target = Target;
    }
}

function LinkForm_$reflection() {
    return record_type("Investfora.CommentForm.LinkForm", [], LinkForm, () => [["Text", string_type], ["Link", string_type], ["Target", class_type("Browser.Types.Range")]]);
}

function getSelectedText(formId) {
    let lowered;
    const parent = document.getElementById(formId);
    if (equals(parent, defaultOf())) {
        return undefined;
    }
    else {
        const parentNode = parent;
        const matchValue = window.getSelection();
        if (equals(matchValue, defaultOf())) {
            return undefined;
        }
        else {
            const x = matchValue;
            if (parentNode.contains(x.anchorNode) && parentNode.contains(x.focusNode)) {
                const y = toString(x);
                if ((x.rangeCount > 0) && (y.length > 0)) {
                    const range = x.getRangeAt(0);
                    return new LinkForm(y, (lowered = y.trim().toLocaleLowerCase(), ((lowered.indexOf("https://") === 0) ? true : (lowered.indexOf("http://") === 0)) ? y.trim() : ""), range);
                }
                else {
                    return new LinkForm(y, y, x.getRangeAt(0));
                }
            }
            else {
                const r = new Range();
                if (!(parentNode.lastChild == null)) {
                    r.setStart(parentNode.lastChild, parentNode.lastChild.childNodes.length);
                    r.setEnd(parentNode.lastChild, parentNode.lastChild.childNodes.length);
                }
                else {
                    const dummyNode = document.createElement("div");
                    parentNode.appendChild(dummyNode);
                    r.setStart(parentNode.lastChild, 0);
                    r.setEnd(parentNode.lastChild, 0);
                }
                return new LinkForm("", "", r);
            }
        }
    }
}

function validateLink(x) {
    const x_1 = x.trim().toLocaleLowerCase();
    if ((((x_1.indexOf("http://") === 0) ? true : (x_1.indexOf("https://") === 0)) && (x_1.length > 9)) && (x_1.indexOf(".") >= 0)) {
        return x_1;
    }
    else {
        return undefined;
    }
}

function insertLink(formId, onAdd, x) {
    const linkText = (x.Text.trim().length === 0) ? x.Link : x.Text;
    const a$0027 = document.createElement("a");
    a$0027.setAttribute("href", x.Link);
    a$0027.setAttribute("target", "_blank");
    a$0027.setAttribute("class", "post-link");
    a$0027.innerText = linkText;
    x.Target.deleteContents();
    x.Target.insertNode(a$0027);
    try {
        const x_1 = document.getElementById(formId);
        if (!(x_1 == null)) {
            onAdd(x_1.innerHTML);
        }
    }
    catch (matchValue) {
    }
}

function tools(formId, onAdd, onImageSelect) {
    let elems_5;
    const patternInput = useFeliz_React__React_useState_Static_1505(undefined);
    const updateLinkForm = patternInput[1];
    const linkForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidLink = patternInput_1[1];
    const commitLink = () => {
        if (linkForm != null) {
            const lf = linkForm;
            const matchValue = validateLink(lf.Link);
            if (matchValue != null) {
                const l = matchValue;
                setValidLink(true);
                insertLink(formId, onAdd, new LinkForm(lf.Text, l, lf.Target));
                updateLinkForm(undefined);
            }
            else {
                setValidLink(false);
            }
        }
    };
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        borderBottom: (((1 + "px ") + "solid") + " ") + "#e6e6e6",
        padding: 5,
        backgroundColor: "#f1f1f1",
    }], (elems_5 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            marginRight: 10,
        }], (elems = [smallIconButton("fas fa-link", (_arg) => {
            if (linkForm != null) {
                updateLinkForm(undefined);
            }
            else {
                updateLinkForm(getSelectedText(formId));
            }
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue_1, imgSelect;
            return append((matchValue_1 = onImageSelect, (matchValue_1 != null) ? ((imgSelect = matchValue_1, singleton(smallIconButton("fas fa-image", (_arg_1) => {
            })))) : (empty())), delay(() => {
                let elements_2, children, elems_1, children_1;
                return singleton(MuiHelpers_createElement(Dialog, [["open", linkForm != null], ["onClose", (_arg_3, v) => {
                    updateLinkForm(undefined);
                }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Legg til lenke"]]), (children = singleton_1(createElement("div", createObj(ofArray([["style", {
                    maxWidth: 500,
                    display: "flex",
                    flexDirection: "column",
                    gap: ((20 + "px ") + 20) + "px",
                }], (elems_1 = [MuiHelpers_createElement(TextField, [["autoFocus", true], ["fullWidth", true], ["label", "URL"], ["size", "small"], ["variant", "standard"], ["defaultValue", (linkForm == null) ? "" : linkForm.Link], ["onChange", (e) => {
                    const x_2 = e.target.value;
                    if (linkForm != null) {
                        const y = linkForm;
                        updateLinkForm(new LinkForm(y.Text, x_2, y.Target));
                    }
                }], ["onTouchStart", (e_1) => {
                    e_1.preventDefault();
                }], ["inputProps", {
                    style: {
                        fontSize: 14 + "px",
                    },
                }], ["error", !patternInput_1[0]], ["onKeyUp", (kc) => {
                    if (kc.key === "Enter") {
                        commitLink();
                    }
                }]]), MuiHelpers_createElement(TextField, [["fullWidth", true], ["label", "Tekst"], ["size", "small"], ["variant", "standard"], ["defaultValue", (linkForm == null) ? "" : linkForm.Text], ["onChange", (e_2) => {
                    const x_4 = e_2.target.value;
                    if (linkForm != null) {
                        const y_1 = linkForm;
                        updateLinkForm(new LinkForm(x_4, y_1.Link, y_1.Target));
                    }
                }], ["onTouchStart", (e_3) => {
                    e_3.preventDefault();
                }], ["inputProps", {
                    style: {
                        fontSize: 14 + "px",
                    },
                }], ["onKeyUp", (kc_1) => {
                    if (kc_1.key === "Enter") {
                        commitLink();
                    }
                }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([pillButtonSmall(ofArray([["backgroundColor", "var(--bg-main)"], ["color", "var(--text-main)"]]), "Avbryt", (_arg_4) => {
                    updateLinkForm(undefined);
                }), pillButtonSmall(empty_1(), "Legg til", (_arg_5) => {
                    commitLink();
                })]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

function textInput(formId, html, emitChange) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const emit = (x) => {
        emitChange(x.target.innerHTML);
    };
    return createElement("div", {
        id: formId,
        contentEditable: true,
        onInput: emit,
        onPaste: (arg) => {
            emit(arg);
        },
        onBlur: (arg_1) => {
            emit(arg_1);
        },
        style: {
            textAlign: "left",
            padding: 5,
            fontSize: 14 + "px",
            whiteSpace: "pre-wrap",
        },
        onFocus: (p) => {
            p.target.innerHTML = html;
        },
    });
}

export function CommentForm(commentFormInputProps) {
    let elems;
    const onImageSelect = commentFormInputProps.onImageSelect;
    const onChange = commentFormInputProps.onChange;
    const html = commentFormInputProps.html;
    const formId = commentFormInputProps.formId;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        border: (((1 + "px ") + "solid") + " ") + "#e6e6e6",
    }], (elems = [tools(formId, onChange, onImageSelect), textInput(formId, html, onChange)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function trySetContent(formId, html) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, 500))).then(() => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const x = document.getElementById(formId);
        if (!(x == null)) {
            console.log(some("Setting inner html"));
            x.innerHTML = html;
            return Promise.resolve();
        }
        else {
            console.log(some("is null"));
            return Promise.resolve();
        }
    }).catch((_arg_1) => {
        return Promise.resolve();
    }))))));
    pr.then();
}

