import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { acceptDisclaimer, hasAcceptedDisclaimer } from "./LocalStorage.js";
import { createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { empty, singleton, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray, singleton as singleton_1 } from "./fable_modules/fable-library-js.4.17.0/List.js";

export function Disclaimer() {
    let elems_2;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setAccepted = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        setAccepted(hasAcceptedDisclaimer());
    });
    return createElement("div", createObj(ofArray([["style", {}], (elems_2 = toList(delay(() => {
        let elems_1, elems, value_10, children;
        return !patternInput[0] ? singleton(createElement("div", createObj(ofArray([["style", {
            backgroundColor: "var(--bg-normal)",
            color: "#FFFFFF",
            fontSize: 0.7 + "rem",
            padding: 5,
            display: "flex",
            alignItems: "center",
        }], (elems_1 = [createElement("span", createObj(singleton_1((elems = [(value_10 = "Ansvarsfraskrivelse: Ved å bruke Aksje.io erkjenner du at du har gjort deg kjent med og forstår vår ", value_10), createElement("a", {
            href: "/financial-disclaimer",
            target: "_blank",
            style: {
                color: "var(--text-main)",
                textDecoration: "underline",
            },
            children: "finansielle ansvarsfraskrivelse",
        }), " og ", createElement("a", {
            href: "/terms-of-use",
            target: "_blank",
            style: {
                color: "var(--text-main)",
                textDecoration: "underline",
            },
            children: "bruksvilkår",
        }), ". Du risikerer å tape alt dersom du baserer dine investeringer på innhold fra Aksje.io."], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), (children = singleton_1(createElement("button", {
            className: "pill-button-small",
            children: "OK",
            onClick: (_arg_1) => {
                setAccepted(true);
                acceptDisclaimer();
            },
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

