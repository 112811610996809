import { toString as toString_2, Union, Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, record_type, bool_type, array_type, option_type, string_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { UserReview_$reflection, UserStatistics_$reflection, UserInfo_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { snackError, loadingIndicatorSmall, loadingIndicatorLarge, Image_userCircle } from "../ViewHelpers.js";
import { ImageSource, ImageForm } from "../Content/ImageForm.js";
import { now, toString as toString_1 } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { utcStringToLocalTimeSpan } from "../Utils.js";
import { ReportObject, Report } from "../Content/ReportForm.js";
import { DefaultBorder, Dimensions_DefaultPadding } from "../Design.js";
import { map as map_1 } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { StockRating__ToTextColorCode, StockRating__ToColorCode, StockRating_FromInt_Z524259A4 } from "../Shared/Shared.js";
import { Feed } from "./UserFeed.js";

class Model extends Record {
    constructor(Username, UserInfo, UserStatistics, Reviews, ShowAllReviews, ErrorMsg) {
        super();
        this.Username = Username;
        this.UserInfo = UserInfo;
        this.UserStatistics = UserStatistics;
        this.Reviews = Reviews;
        this.ShowAllReviews = ShowAllReviews;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.User.Model", [], Model, () => [["Username", string_type], ["UserInfo", option_type(UserInfo_$reflection())], ["UserStatistics", option_type(UserStatistics_$reflection())], ["Reviews", option_type(array_type(UserReview_$reflection()))], ["ShowAllReviews", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UserInfoResponse", "StatisticsResponse", "ReviewsResponse", "ToggleShowAllReviews", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.User.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserInfo_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", UserInfo_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserStatistics_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", UserStatistics_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserReview_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserReview_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [], []]);
}

function init(username) {
    return new Model(username, undefined, undefined, undefined, false, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Username, model.UserInfo, res_1.fields[0], model.Reviews, model.ShowAllReviews, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Username, model.UserInfo, model.UserStatistics, res_2.fields[0], model.ShowAllReviews, model.ErrorMsg);
            }
        }
        case 3:
            return new Model(model.Username, model.UserInfo, model.UserStatistics, model.Reviews, true, model.ErrorMsg);
        case 4:
            return new Model(model.Username, model.UserInfo, model.UserStatistics, model.Reviews, model.ShowAllReviews, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Username, model.UserInfo, model.UserStatistics, model.Reviews, model.ShowAllReviews, "Kunne ikke laste brukerinfo. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.Username, res.fields[0], model.UserStatistics, model.Reviews, model.ShowAllReviews, model.ErrorMsg);
            }
        }
    }
}

function fetchReviews(userId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(UserReview_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/user/reviews/${userId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(UserReview_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchStatistics(userId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(UserStatistics_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/user/stats/${userId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(UserStatistics_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchUserInfo(username, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(UserInfo_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/user/${username}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(UserInfo_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            const res = _arg_2;
            dispatch(new Message(0, [res]));
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                fetchReviews(x_1.UserId, dispatch);
                fetchStatistics(x_1.UserId, dispatch);
                return Promise.resolve();
            }
            else {
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function DrawImage(uInfo) {
    let elems;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showForm = patternInput[0];
    const setShowForm = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: ((0 + "px ") + 20) + "px",
    }], (elems = toList(delay(() => append(singleton(Image_userCircle(uInfo.UserId, 150, true, patternInput_1[0])), delay(() => (uInfo.IsMe ? append(singleton(createElement("button", {
        style: {
            cursor: "pointer",
            marginTop: 5,
            border: "none",
            background: "none",
            color: "var(--text-main)",
        },
        onClick: (_arg) => {
            setShowForm(!showForm);
        },
        children: "Endre",
    })), delay(() => (showForm ? singleton(createElement(ImageForm, {
        src: new ImageSource(),
        onClose: (didChange) => {
            if (didChange) {
                patternInput_1[1](toString_1(now(), "mmss"));
            }
            setShowForm(false);
        },
    })) : empty_1()))) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function DrawBaseInfo(uInfo) {
    let elems_2;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showReport = patternInput[0];
    const setShowReport = patternInput[1];
    return createElement("div", createObj(ofArray([["style", {
        margin: ((0 + "px ") + 20) + "px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_2 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("span", createObj(ofArray([["style", {
            fontSize: 2.5 + "rem",
            fontWeight: "bold",
        }], (elems = toList(delay(() => append(singleton(uInfo.Username), delay(() => (uInfo.Official ? singleton(createElement("span", {
            style: {
                marginLeft: 5,
                color: "var(--text-main)",
            },
            className: "fas fa-shield-alt",
        })) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(uInfo.Official ? singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                display: "block",
                color: "var(--text-main)",
            },
            children: "Aksje.io administrator",
        })) : empty_1(), delay(() => append(singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
            },
            children: `Ble med ${utcStringToLocalTimeSpan(uInfo.Joined)}`,
        })), delay(() => {
            let elems_1;
            return ((isAuthenticated) && !uInfo.IsMe) ? append(singleton(createElement("button", createObj(ofArray([["className", "pill-button-small"], ["onClick", (_arg) => {
                setShowReport(!showReport);
            }], ["style", {
                marginTop: 10 + "px",
            }], (elems_1 = [createElement("span", {
                style: {
                    marginRight: 5,
                },
                className: "fas fa-flag",
            }), createElement("span", {
                children: ["Rapporter"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => (showReport ? singleton(createElement(Report, {
                target: new ReportObject(4, [uInfo.UserId]),
                onClose: () => {
                    setShowReport(false);
                },
            })) : empty_1()))) : empty_1();
        }))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function drawStats(stats) {
    let elems_1;
    const statEntry = (title, entry) => {
        let elems;
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }], (elems = [createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                fontStyle: "italic",
            },
            children: title,
        }), createElement("span", {
            style: {
                fontSize: 1.7 + "rem",
                color: "var(--text-main)",
                fontWeight: "bold",
                margin: ((0 + "px ") + 5) + "px",
            },
            children: entry,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        margin: ((0 + "px ") + 20) + "px",
    }], (elems_1 = [statEntry("Antall analyser", int32ToString(stats.NumReviews)), statEntry("Antall kommentarer", int32ToString(stats.NumReplies))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function drawUserInfo(model) {
    let elems_2;
    return createElement("div", createObj(ofArray([["style", createObj(ofArray([["marginTop", 3.5 + "vh"], ["padding", Dimensions_DefaultPadding], ["backgroundColor", "#FFFFFF"], ["borderRadius", 5], DefaultBorder, ["display", "flex"], ["flexWrap", "wrap"], ["justifyContent", "space-around"]]))], (elems_2 = toList(delay(() => {
        let elems;
        const matchValue = model.UserInfo;
        if (matchValue != null) {
            const uInfo = matchValue;
            return append(singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
            }], (elems = [createElement(DrawImage, uInfo), createElement(DrawBaseInfo, uInfo)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_1;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                }], (elems_1 = toList(delay(() => {
                    const matchValue_1 = model.UserStatistics;
                    return (matchValue_1 != null) ? singleton(drawStats(matchValue_1)) : singleton(loadingIndicatorLarge());
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
            }));
        }
        else {
            return singleton(loadingIndicatorLarge());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function drawReviews(model, dispatch) {
    let elems_8;
    return createElement("div", createObj(ofArray([["style", createObj(ofArray([["backgroundColor", "#FFFFFF"], ["padding", Dimensions_DefaultPadding], ["borderRadius", 5], DefaultBorder, ["display", "flex"], ["flexDirection", "column"], ["alignItems", "center"]]))], (elems_8 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("span", {
            style: {
                fontSize: 2 + "rem",
                fontWeight: 500,
            },
            children: "Aktive analyser",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_7;
            const matchValue = model.Reviews;
            if (matchValue != null) {
                const reviews = matchValue;
                const aboveThreshold = reviews.length > 7;
                const reviewsToShow = aboveThreshold ? (model.ShowAllReviews ? reviews : reviews.slice(0, (7 - 1) + 1)) : reviews;
                return (reviews.length === 0) ? singleton(createElement("span", {
                    children: ["Ingen analyser enda"],
                })) : append(singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    width: 100 + "%",
                    margin: (((1 + "vw") + " ") + 0) + "px",
                    gap: ((10 + "px ") + 10) + "px",
                }], (elems_7 = map_1((x) => {
                    let elems_6, elems_2, elems_1, elems_5, elems_4, elems_3;
                    const rating = StockRating_FromInt_Z524259A4(x.Rating);
                    const link = (x.AssetType === 1) ? (`/stock/${x.Market}/${x.Asset}`) : (`/crypto/${x.Asset}`);
                    return createElement("div", createObj(ofArray([["className", "review-listing-holder-tiny"], (elems_6 = [createElement("div", createObj(ofArray([["className", "review-listing-rating-tiny"], ["style", {
                        backgroundColor: StockRating__ToColorCode(rating),
                        textAlign: "center",
                    }], (elems_2 = [createElement("a", createObj(ofArray([["href", `/post?src=rv&sid=${x.ReviewId}`], (elems_1 = [createElement("span", {
                        style: {
                            color: StockRating__ToTextColorCode(rating),
                        },
                        children: toString_2(rating),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "space-between",
                    }], (elems_5 = [createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5,
                    }], (elems_4 = [createElement("a", createObj(ofArray([["href", link], (elems_3 = [createElement("span", {
                        style: {
                            fontWeight: "bold",
                            fontSize: 0.8 + "rem",
                        },
                        children: (x.AssetType === 2) ? x.Asset : (`${x.Asset}.${x.Market}`),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
                }, reviewsToShow), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => (aboveThreshold ? append(singleton(createElement("br", {})), delay(() => singleton(createElement("button", {
                    className: "pill-button-small",
                    onClick: (_arg) => {
                        dispatch(new Message(3, []));
                    },
                    children: "Vis alle",
                })))) : empty_1())));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

function drawMostRecentReviews(model) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems = toList(delay(() => {
        const matchValue = model.UserInfo;
        if (matchValue != null) {
            const ui = matchValue;
            return singleton(createElement(Feed, {
                userId: ui.UserId,
            }));
        }
        else {
            return empty_1();
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function view(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "asset-column"], (elems = [snackError(model.ErrorMsg, () => {
        dispatch(new Message(4, []));
    }), drawUserInfo(model), drawReviews(model, dispatch), drawMostRecentReviews(model)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function User(userInputProps) {
    const username = userInputProps.username;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(username));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchUserInfo(username, dispatch);
    });
    return view(patternInput[0], dispatch);
}

