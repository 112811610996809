import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, option_type, bool_type, string_type, array_type, union_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { ReplyForm_$reflection, ReplyForm as ReplyForm_1, Reply, RepliesResponse_$reflection, GenericResponse_$reflection, Reply_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { utcStringToLocalTimeSpan, checkError, updateInputField, tryFocusInput } from "../Utils.js";
import { map, append, sortBy } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { int32ToString, createObj, uncurry2, comparePrimitives } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map as map_1, defaultArg, some } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { DialogActions, DialogContent, Dialog } from "@mui/material";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { snackError, User_usernameText } from "../ViewHelpers.js";
import { toArray, empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { StockRating, StockRating__ToColorCode } from "../Shared/Shared.js";
import { Content } from "./ContentParser.js";
import { CommentForm } from "./CommentForm.js";
import { Report, ReportObject } from "./ReportForm.js";
import { useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";

export class ParentContent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssetReview", "AssetPost"];
    }
}

export function ParentContent_$reflection() {
    return union_type("Investfora.Replies.ParentContent", [], ParentContent, () => [[["Item", int32_type]], [["Item", int32_type]]]);
}

export function ParentContent__ReplyFieldId(this$) {
    if (this$.tag === 1) {
        return `reply-asset-post-${this$.fields[0]}`;
    }
    else {
        return `reply-review-${this$.fields[0]}`;
    }
}

export function ParentContent__ToReplyUrl(this$) {
    if (this$.tag === 1) {
        return "/api/asset/post/reply";
    }
    else {
        return "/api/review/reply";
    }
}

export function ParentContent__ToUpvoteUrl_1505(this$, replyId) {
    if (this$.tag === 1) {
        return `/api/asset/post/reply/upvote/${replyId}`;
    }
    else {
        return `/api/review/reply/upvote/${replyId}`;
    }
}

export function ParentContent__ToDownvoteUrl_1505(this$, replyId) {
    if (this$.tag === 1) {
        return `/api/asset/post/reply/downvote/${replyId}`;
    }
    else {
        return `/api/review/reply/downvote/${replyId}`;
    }
}

export function ParentContent__ToLoadAllUrl(this$) {
    if (this$.tag === 1) {
        return `/api/asset/post/replies/${this$.fields[0]}`;
    }
    else {
        return `/api/review/reply/review/${this$.fields[0]}`;
    }
}

export function ParentContent__ToDeleteUrl_1505(this$, replyId) {
    if (this$.tag === 1) {
        return `/api/asset/post/reply/delete/${replyId}`;
    }
    else {
        return `/api/review/reply/delete/${replyId}`;
    }
}

class Model extends Record {
    constructor(Parent, Replies, NumReplies, ReplyForm, FormActive, Processing, DeleteForm, ReportForm, ErrorMsg) {
        super();
        this.Parent = Parent;
        this.Replies = Replies;
        this.NumReplies = (NumReplies | 0);
        this.ReplyForm = ReplyForm;
        this.FormActive = FormActive;
        this.Processing = Processing;
        this.DeleteForm = DeleteForm;
        this.ReportForm = ReportForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Replies.Model", [], Model, () => [["Parent", ParentContent_$reflection()], ["Replies", array_type(Reply_$reflection())], ["NumReplies", int32_type], ["ReplyForm", string_type], ["FormActive", bool_type], ["Processing", bool_type], ["DeleteForm", option_type(Reply_$reflection())], ["ReportForm", option_type(Reply_$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToggleFormActive", "UpdateReply", "PublishReply", "ReplyResponse", "Upvote", "Downvote", "UpvoteResponse", "DownvoteResponse", "AllResponse", "DeleteReply", "AbortDelete", "CommitDelete", "DeleteResponse", "ReportReply", "AbortReport", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Replies.Message", [], Message, () => [[], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Reply_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Reply_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", int32_type]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [RepliesResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", RepliesResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", Reply_$reflection()]], [], [["Item", Reply_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Reply_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Reply_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", Reply_$reflection()]], [], []]);
}

function init(parent, replies, numReplies) {
    return new Model(parent, replies, numReplies, "", false, false, undefined, undefined, undefined);
}

function tryAutoFocus(parent) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, 300))).then(() => {
        tryFocusInput(ParentContent__ReplyFieldId(parent));
        return Promise.resolve();
    }))));
    pr.then();
}

function update(model, msg) {
    switch (msg.tag) {
        case 0: {
            if (!model.FormActive) {
                tryAutoFocus(model.Parent);
            }
            return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, !model.FormActive, model.Processing, model.DeleteForm, model.ReportForm, model.ErrorMsg);
        }
        case 1:
            return new Model(model.Parent, model.Replies, model.NumReplies, msg.fields[0], model.FormActive, model.Processing, model.DeleteForm, model.ReportForm, model.ErrorMsg);
        case 2:
            if (model.ReplyForm.length > 0) {
                return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, true, model.DeleteForm, model.ReportForm, model.ErrorMsg);
            }
            else {
                return model;
            }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, false, model.DeleteForm, model.ReportForm, (err.tag === 2) ? (((err.fields[0].status) === 429) ? "Du er midlertidig sperret fra å kommentere for å ha publisert for mange innlegg over en kort tidsperiode." : "Kunne ikke publisere kommentar.") : "Kunne ikke publisere kommentar.");
            }
            else {
                const newReplies = sortBy((x_2) => x_2.Id, append([res.fields[0]], model.Replies), {
                    Compare: comparePrimitives,
                });
                updateInputField(ParentContent__ReplyFieldId(model.Parent), "");
                return new Model(model.Parent, newReplies, model.NumReplies, "", false, false, model.DeleteForm, model.ReportForm, model.ErrorMsg);
            }
        }
        case 4:
            return new Model(model.Parent, map((x_4) => {
                let matchValue;
                if (x_4.Id !== msg.fields[0]) {
                    return x_4;
                }
                else {
                    return new Reply(x_4.Id, x_4.ParentId, x_4.Content, (matchValue = (x_4.VoteState | 0), (matchValue === -1) ? (x_4.Votes + 2) : ((matchValue === 1) ? x_4.Votes : (x_4.Votes + 1))), x_4.NumVotes, x_4.User, x_4.IsMine, x_4.Published, 1, x_4.Removed);
                }
            }, model.Replies), model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, model.DeleteForm, model.ReportForm, model.ErrorMsg);
        case 5:
            return new Model(model.Parent, map((x_5) => {
                let matchValue_1;
                if (x_5.Id !== msg.fields[0]) {
                    return x_5;
                }
                else {
                    return new Reply(x_5.Id, x_5.ParentId, x_5.Content, (matchValue_1 = (x_5.VoteState | 0), (matchValue_1 === -1) ? x_5.Votes : ((matchValue_1 === 1) ? (x_5.Votes - 2) : (x_5.Votes - 1))), x_5.NumVotes, x_5.User, x_5.IsMine, x_5.Published, -1, x_5.Removed);
                }
            }, model.Replies), model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, model.DeleteForm, model.ReportForm, model.ErrorMsg);
        case 8: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                const x_6 = res_1.fields[0];
                return new Model(model.Parent, x_6.Replies, x_6.Replies.length, model.ReplyForm, model.FormActive, model.Processing, model.DeleteForm, model.ReportForm, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 9:
            return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, msg.fields[0], model.ReportForm, model.ErrorMsg);
        case 10:
            return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, undefined, model.ReportForm, model.ErrorMsg);
        case 12: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                checkError(res_2.fields[0]);
                return model;
            }
            else {
                const newRepliesList_2 = model.Replies.filter((y_1) => (y_1.Id !== res_2.fields[0].Id));
                return new Model(model.Parent, newRepliesList_2, newRepliesList_2.length, model.ReplyForm, model.FormActive, model.Processing, undefined, model.ReportForm, model.ErrorMsg);
            }
        }
        case 13:
            return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, model.DeleteForm, msg.fields[0], model.ErrorMsg);
        case 14:
            return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, model.DeleteForm, undefined, model.ErrorMsg);
        case 6: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                checkError(res_3.fields[0]);
                return model;
            }
            else {
                return model;
            }
        }
        case 7: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                checkError(res_4.fields[0]);
                return model;
            }
            else {
                return model;
            }
        }
        case 15:
            return new Model(model.Parent, model.Replies, model.NumReplies, model.ReplyForm, model.FormActive, model.Processing, model.DeleteForm, model.ReportForm, undefined);
        default:
            return model;
    }
}

function postReply(parent, reply, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(2, [reply]));
        if (reply.length > 0) {
            const requestPath = ParentContent__ToReplyUrl(parent);
            const replyForm = new ReplyForm_1((parent.tag === 1) ? parent.fields[0] : parent.fields[0], reply);
            return ((body = Auto_generateBoxedEncoder_437914C6(ReplyForm_$reflection(), undefined, undefined, undefined)(replyForm), (decoder = Auto_generateBoxedDecoder_Z6670B51(Reply_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Reply_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })())))))).then((_arg_2) => {
                dispatch(new Message(3, [_arg_2]));
                return Promise.resolve();
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    pr_1.then();
}

function upvote(parent, replyId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(4, [replyId]));
        const requestPath = ParentContent__ToUpvoteUrl_1505(parent, replyId);
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(6, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function downvote(parent, replyId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(5, [replyId]));
        const requestPath = ParentContent__ToDownvoteUrl_1505(parent, replyId);
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(7, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchAll(parent, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = ParentContent__ToLoadAllUrl(parent);
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(RepliesResponse_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(RepliesResponse_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(8, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function deleteReply(parent, replyId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = ParentContent__ToDeleteUrl_1505(parent, replyId);
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(Reply_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Reply_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(12, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawDeleteForm(dispatch, deleteForm) {
    let elements_2, children, elems, children_1;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(10, []));
    }], (elements_2 = [(children = singleton(createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("span", {
        children: ["Er du sikker på at du ønsker å slette denne kommentaren?"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([createElement("button", {
        className: "pill-button-small",
        onClick: (_arg_2) => {
            dispatch(new Message(10, []));
        },
        children: "Nei",
    }), createElement("button", {
        className: "pill-button-small",
        onClick: (_arg_3) => {
            dispatch(new Message(11, [deleteForm]));
        },
        children: "Ja",
    })]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function replyHeader(reply) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "reply-header"], (elems = [User_usernameText(reply.User, reply.Removed), createElement("span", {
        style: {
            color: "#888",
            marginLeft: 5,
            marginRight: 5,
            fontSize: 0.8 + "rem",
        },
        children: "-",
    }), createElement("span", {
        style: {
            color: "#888",
            fontStyle: "italic",
            fontSize: 0.8 + "rem",
        },
        children: utcStringToLocalTimeSpan(reply.Published),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function replyFooter(model, dispatch, reply) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", "reply-footer"], (elems_4 = toList(delay(() => {
        let elems;
        return append_1((isAuthenticated) ? append_1((!reply.Removed && !reply.IsMine) ? singleton_1(createElement("button", createObj(ofArray([["className", "icon-button"], ["style", createObj(toList(delay(() => append_1(singleton_1(["margin", 5]), delay(() => (!reply.IsMine ? singleton_1(["marginRight", 15]) : empty_1()))))))], ["onClick", (_arg) => {
            dispatch(new Message(13, [reply]));
        }], (elems = [createElement("i", {
            className: "fas fa-flag",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => {
            let elems_1;
            return append_1(reply.IsMine ? singleton_1(createElement("button", createObj(ofArray([["className", "icon-button"], ["style", {
                margin: 5,
                marginRight: 15,
            }], ["onClick", (_arg_1) => {
                deleteReply(model.Parent, reply.Id, dispatch);
            }], (elems_1 = [createElement("i", {
                className: "fas fa-trash",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => {
                let elems_2;
                return singleton_1(createElement("button", createObj(ofArray([["className", "vote-button-small"], ["style", {
                    color: (reply.VoteState === 1) ? StockRating__ToColorCode(new StockRating(0, [])) : "#aaaaaa",
                }], ["disabled", reply.Removed], ["onClick", (_arg_2) => {
                    if ((reply.VoteState !== 1) && !reply.Removed) {
                        upvote(model.Parent, reply.Id, dispatch);
                    }
                }], (elems_2 = [createElement("span", {
                    className: "fas fa-caret-up",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
            }));
        })) : empty_1(), delay(() => append_1(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                color: !reply.Removed ? ((reply.Votes > 0) ? "#36cc08" : ((reply.Votes < 0) ? "#cc3608" : "var(--text-dim)")) : "var(--text-dim)",
            },
            children: int32ToString(reply.Votes),
        })), delay(() => {
            let elems_3;
            return (isAuthenticated) ? singleton_1(createElement("button", createObj(ofArray([["className", "vote-button-small"], ["style", {
                color: (reply.VoteState === -1) ? StockRating__ToColorCode(new StockRating(4, [])) : "#aaaaaa",
                marginRight: 15,
            }], ["disabled", reply.Removed], ["onClick", (_arg_3) => {
                if ((reply.VoteState !== -1) && !reply.Removed) {
                    downvote(model.Parent, reply.Id, dispatch);
                }
            }], (elems_3 = [createElement("span", {
                className: "fas fa-caret-down",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : empty_1();
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function view(model, dispatch) {
    const xs_13 = toArray(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(15, []));
    })), delay(() => {
        let elems;
        return append_1((model.Replies.length < model.NumReplies) ? singleton_1(createElement("div", createObj(ofArray([["className", "post-load-replies"], (elems = [createElement("a", {
            style: {
                userSelect: "none",
                cursor: "pointer",
            },
            onClick: (_arg_1) => {
                fetchAll(model.Parent, dispatch);
            },
            children: `Se alle kommentarer (${model.NumReplies})`,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => append_1(map((x_2) => {
            let elems_2, elems_1;
            return createElement("div", createObj(ofArray([["className", "reply"], (elems_2 = [replyHeader(x_2), createElement("div", createObj(ofArray([["style", {
                "grid-row": 2,
                padding: ((((((5 + "px ") + 10) + "px ") + 5) + "px ") + 10) + "px",
                fontSize: 0.8 + "rem",
                textAlign: "left",
            }], (elems_1 = [createElement(Content, {
                content: x_2.Content,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), replyFooter(model, dispatch, x_2)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }, sortBy((x) => x.Published, model.Replies, {
            Compare: comparePrimitives,
        })), delay(() => {
            let elems_6;
            return append_1(singleton_1(createElement("div", createObj(ofArray([["className", "post-reply-holder"], (elems_6 = toList(delay(() => {
                let elems_3, elems_5, elems_4;
                return !(isAuthenticated) ? singleton_1(createElement("a", {
                    href: "/sign-in",
                    style: {
                        padding: 5,
                    },
                    children: "Logg inn for å kommentere.",
                })) : (!(emailVerified) ? singleton_1(createElement("span", {
                    style: {
                        padding: 5,
                        fontSize: 0.8 + "rem",
                    },
                    children: "Verifiser e-post adresse for å kommentere.",
                })) : (!model.FormActive ? singleton_1(createElement("div", createObj(ofArray([["style", {
                    cursor: "pointer",
                    padding: 5,
                    textAlign: "left",
                }], ["onClick", (_arg_2) => {
                    dispatch(new Message(0, []));
                }], (elems_3 = [createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        color: "var(--text-dim)",
                    },
                    children: "Svar...",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : singleton_1(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                }], (elems_5 = [createElement(CommentForm, {
                    formId: ParentContent__ReplyFieldId(model.Parent),
                    html: model.ReplyForm,
                    onChange: (arg) => {
                        dispatch(new Message(1, [arg]));
                    },
                }), createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: 5,
                }], (elems_4 = [createElement("button", {
                    className: "pill-button-small",
                    children: "Close",
                    style: {
                        marginRight: 5,
                    },
                    onClick: (_arg_3) => {
                        dispatch(new Message(0, []));
                    },
                }), createElement("button", {
                    className: "pill-button-small",
                    onClick: (_arg_4) => {
                        if ((model.ReplyForm.length > 0) && !model.Processing) {
                            postReply(model.Parent, model.ReplyForm, dispatch);
                        }
                    },
                    children: "Publiser kommentar",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                let matchValue;
                return append_1((matchValue = model.DeleteForm, (matchValue != null) ? singleton_1(drawDeleteForm(dispatch, matchValue)) : (empty_1())), delay(() => {
                    const matchValue_1 = model.ReportForm;
                    if (matchValue_1 != null) {
                        const reply = matchValue_1;
                        const tgt = (model.Parent.tag === 1) ? (new ReportObject(3, [reply.Id])) : (new ReportObject(1, [reply.Id]));
                        return singleton_1(createElement(Report, {
                            target: tgt,
                            onClose: () => {
                                dispatch(new Message(14, []));
                            },
                        }));
                    }
                    else {
                        return empty_1();
                    }
                }));
            }));
        }))));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_13);
}

export function Replies(repliesInputProps) {
    const numReplies = repliesInputProps.numReplies;
    const replies = repliesInputProps.replies;
    const parent = repliesInputProps.parent;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(parent, replies, numReplies));
    return view(patternInput[0], patternInput[1]);
}

