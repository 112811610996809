import { toString as toString_1, Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, class_type, record_type, option_type, string_type, float64_type, bool_type, int32_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { StockRating__ToTextColorCode, StockRating__ToColorCode, StockRating_FromInt_Z524259A4, StockRating__ToInt, StockRating, StockRating_$reflection } from "../Shared/Shared.js";
import { PeriodType__ToPeriodEnd, PeriodType__Validate, PeriodSpan__ToValue, PeriodSpan__ToNumber, PeriodSpan_FromValue, PeriodType__SetExactDate_7F9DDECF, PeriodType__UpdateSpanLength_Z524259A4, PeriodType__Morph_Z721C83C5, PeriodSpan__ToDraft, PeriodType, PeriodSpan, PeriodType_$reflection } from "../Helpers.js";
import { tryGetReviewDraft, deleteDraft, ReviewDraft, storeDraft, ReviewDraft_$reflection } from "../LocalStorage.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { op_Subtraction, addDays, parse, now, toString } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { uncurry2, createObj, equals } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { CommentForm, trySetContent } from "../Content/CommentForm.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { cons, singleton as singleton_1, empty as empty_1, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { append, empty, singleton, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, MenuItem, Select, FormControl, Input, TextField } from "@mui/material";
import { parse as parse_1 } from "../fable_modules/fable-library-js.4.17.0/Int32.js";
import { snackError, loadingIndicatorSmall, pillButtonSmall } from "../ViewHelpers.js";
import { replace, format } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { parse as parse_2 } from "../fable_modules/fable-library-js.4.17.0/Double.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { totalDays } from "../fable_modules/fable-library-js.4.17.0/TimeSpan.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Post_$reflection, AssetReviewForm_$reflection, AssetReviewForm } from "../Shared/ApiDataTypes.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString as toString_2 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";

class TargetStep extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["YesNo", "EnterTarget"];
    }
}

function TargetStep_$reflection() {
    return union_type("Investfora.ReviewForm.TargetStep", [], TargetStep, () => [[], []]);
}

class Step extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetRating", "SetPeriod", "SetTarget", "GiveComment", "Published"];
    }
}

function Step_$reflection() {
    return union_type("Investfora.ReviewForm.Step", [], Step, () => [[], [], [["Item", TargetStep_$reflection()]], [], []]);
}

function Step_FromInt_Z524259A4(x) {
    switch (x) {
        case 2:
            return new Step(1, []);
        case 3:
            return new Step(2, [new TargetStep(0, [])]);
        case 4:
            return new Step(3, []);
        default:
            return new Step(0, []);
    }
}

function Step__ToDraftId(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        case 3:
            return 4;
        default:
            return 1;
    }
}

class Model extends Record {
    constructor(AssetId, AutoOpenMode, CurrentStep, Rating, Period, WithTargetPrice, TargetPrice, Currency, Comment$, ValidPeriod, ValidTarget, Processing, RegisterModal, ErrorMsg) {
        super();
        this.AssetId = (AssetId | 0);
        this.AutoOpenMode = AutoOpenMode;
        this.CurrentStep = CurrentStep;
        this.Rating = Rating;
        this.Period = Period;
        this.WithTargetPrice = WithTargetPrice;
        this.TargetPrice = TargetPrice;
        this.Currency = Currency;
        this.Comment = Comment$;
        this.ValidPeriod = ValidPeriod;
        this.ValidTarget = ValidTarget;
        this.Processing = Processing;
        this.RegisterModal = RegisterModal;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.ReviewForm.Model", [], Model, () => [["AssetId", int32_type], ["AutoOpenMode", bool_type], ["CurrentStep", Step_$reflection()], ["Rating", StockRating_$reflection()], ["Period", PeriodType_$reflection()], ["WithTargetPrice", bool_type], ["TargetPrice", float64_type], ["Currency", option_type(string_type)], ["Comment", string_type], ["ValidPeriod", bool_type], ["ValidTarget", bool_type], ["Processing", bool_type], ["RegisterModal", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FromDraft", "SetStep", "SelectRating", "SelectPeriodType", "SelectPeriodSpan", "SetDate", "SetHasTarget", "UpdateTargetPrice", "InvalidatePeriod", "InvalidateTarget", "UpdateComment", "Abandon", "SetProcessing", "PublishFailed", "ToggleRegisterModal", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.ReviewForm.Message", [], Message, () => [[["Item", ReviewDraft_$reflection()]], [["Item", Step_$reflection()]], [["Item", StockRating_$reflection()]], [["Item", string_type]], [["Item", int32_type]], [["Item", class_type("System.DateTime")]], [], [["Item", float64_type]], [], [], [["Item", string_type]], [], [["Item", bool_type]], [["Item", FetchError_$reflection()]], [["Item", bool_type]], []]);
}

function defaultPeriod() {
    return new PeriodType(0, [new PeriodSpan(2, [3])]);
}

function defaultValues() {
    return [new StockRating(2, []), defaultPeriod(), 0, false, "", new Step(0, [])];
}

function init(assetId, autoOpenMode) {
    return new Model(assetId, autoOpenMode, new Step(0, []), new StockRating(2, []), defaultPeriod(), false, 0, undefined, "0.0", true, true, false, false, undefined);
}

function updateDraft(model) {
    let periodType;
    const matchValue = model.Period;
    periodType = ((matchValue.tag === 1) ? 2 : ((matchValue.tag === 2) ? 3 : 1));
    let periodSpan;
    const matchValue_1 = model.Period;
    periodSpan = ((matchValue_1.tag === 0) ? PeriodSpan__ToDraft(matchValue_1.fields[0]) : undefined);
    let periodDate;
    const matchValue_2 = model.Period;
    periodDate = ((matchValue_2.tag === 1) ? toString(matchValue_2.fields[0], "yyyy-MM-dd") : undefined);
    storeDraft(model.AssetId, new ReviewDraft(StockRating__ToInt(model.Rating), model.WithTargetPrice ? model.TargetPrice : undefined, periodType, periodSpan, periodDate, equals(model.Period, new PeriodType(2, [])), model.Comment, Step__ToDraftId(model.CurrentStep), toString(now(), "yyyy-MM-dd")));
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const x_3 = msg.fields[0];
            const model$0027 = new Model(model.AssetId, model.AutoOpenMode, x_3, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
            if (!equals(x_3, new Step(4, []))) {
                updateDraft(model$0027);
            }
            else {
                deleteDraft(model.AssetId);
            }
            if (equals(x_3, new Step(3, [])) && (model.Comment.length > 0)) {
                trySetContent("review-comment-form", model.Comment);
            }
            return model$0027;
        }
        case 2: {
            const model$0027_1 = new Model(model.AssetId, model.AutoOpenMode, new Step(1, []), msg.fields[0], model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
            updateDraft(model$0027_1);
            return model$0027_1;
        }
        case 3: {
            const model$0027_2 = new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, PeriodType__Morph_Z721C83C5(model.Period, msg.fields[0]), model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, true, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
            updateDraft(model$0027_2);
            return model$0027_2;
        }
        case 4:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, PeriodType__UpdateSpanLength_Z524259A4(model.Period, msg.fields[0]), model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, true, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
        case 5:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, PeriodType__SetExactDate_7F9DDECF(model.Period, msg.fields[0]), model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
        case 6:
            return new Model(model.AssetId, model.AutoOpenMode, new Step(2, [new TargetStep(1, [])]), model.Rating, model.Period, true, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
        case 7: {
            const x_8 = msg.fields[0];
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, x_8, model.Currency, model.Comment, model.ValidPeriod, x_8 >= 0, model.Processing, model.RegisterModal, model.ErrorMsg);
        }
        case 8:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, false, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
        case 9:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, false, model.Processing, model.RegisterModal, model.ErrorMsg);
        case 10: {
            const model$0027_3 = new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, msg.fields[0], model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
            updateDraft(model$0027_3);
            return model$0027_3;
        }
        case 11: {
            deleteDraft(model.AssetId);
            const patternInput = defaultValues();
            return new Model(model.AssetId, model.AutoOpenMode, patternInput[5], patternInput[0], patternInput[1], patternInput[3], patternInput[2], model.Currency, patternInput[4], true, true, false, false, model.ErrorMsg);
        }
        case 12:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, msg.fields[0], model.RegisterModal, model.ErrorMsg);
        case 13: {
            const err = msg.fields[0];
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, false, model.RegisterModal, (err.tag === 3) ? "En nettverksfeil oppsto. Kontroller internettilkoblingen og prøv igjen senere." : ((err.tag === 2) ? (((err.fields[0].status) === 401) ? "" : (((err.fields[0].status) === 409) ? "Du publiserte nylig en analyse for denne aksjen. Du må vente en stund før du kan publisere en ny." : (((err.fields[0].status) === 429) ? "Du er midlertidig blokkert fra å publisere for å ha publisert for mange innlegg/analyser/kommentarer over en kort periode. Vennligst prøv igjen senere." : "Ooups, noe gikk galt. Vennligst prøv igjen senere."))) : "Ooups, noe gikk galt. Vennligst prøv igjen senere."));
        }
        case 14:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, msg.fields[0], model.ErrorMsg);
        case 15:
            return new Model(model.AssetId, model.AutoOpenMode, model.CurrentStep, model.Rating, model.Period, model.WithTargetPrice, model.TargetPrice, model.Currency, model.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, undefined);
        default: {
            const x = msg.fields[0];
            let period;
            const matchValue = x.PeriodType | 0;
            switch (matchValue) {
                case 2: {
                    const matchValue_1 = x.PeriodDate;
                    if (matchValue_1 != null) {
                        const pd = matchValue_1;
                        try {
                            period = (new PeriodType(1, [parse(pd)]));
                        }
                        catch (matchValue_2) {
                            period = defaultPeriod();
                        }
                    }
                    else {
                        period = defaultPeriod();
                    }
                    break;
                }
                case 3: {
                    period = (new PeriodType(2, []));
                    break;
                }
                default: {
                    const matchValue_3 = x.PeriodSpan;
                    if (matchValue_3 != null) {
                        const ps = matchValue_3;
                        const matchValue_4 = PeriodSpan_FromValue(ps.Value, ps.Num);
                        period = ((matchValue_4 != null) ? (new PeriodType(0, [matchValue_4])) : defaultPeriod());
                    }
                    else {
                        period = defaultPeriod();
                    }
                }
            }
            const Rating = StockRating_FromInt_Z524259A4(x.Rating);
            let TargetPrice;
            const matchValue_5 = x.TargetPrice;
            TargetPrice = ((matchValue_5 == null) ? 0 : matchValue_5);
            const WithTargetPrice = x.TargetPrice != null;
            return new Model(model.AssetId, model.AutoOpenMode, Step_FromInt_Z524259A4(x.CurrentStep), Rating, period, WithTargetPrice, TargetPrice, model.Currency, x.Comment, model.ValidPeriod, model.ValidTarget, model.Processing, model.RegisterModal, model.ErrorMsg);
        }
    }
}

function sectionTitle(x) {
    return createElement("span", {
        style: {
            marginBottom: 5,
            fontWeight: 500,
        },
        children: x,
    });
}

function summaryTitle(x) {
    return createElement("span", {
        style: {
            fontStyle: "italic",
        },
        children: x,
    });
}

function inputHolder(x) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 10,
            width: 100 + "%",
            maxWidth: 600,
            padding: 10,
            border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
            borderRadius: 5,
            backgroundColor: "#FFFFFF",
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function summaryHolder(x) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
            width: 100 + "%",
            maxWidth: 600,
            padding: 10,
            marginBottom: 5,
            border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
            borderRadius: 5,
            backgroundColor: "#FFFFFF",
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function elevatedText(x) {
    return createElement("span", {
        style: {
            fontWeight: "bold",
        },
        children: x,
    });
}

function drawSetRating(model, dispatch) {
    let elems;
    const ratingButton = (r) => createElement("button", {
        className: `rating-button-${(r.tag === 1) ? (equals(r, model.Rating) ? "rating-weak-buy" : "") : ((r.tag === 2) ? (equals(r, model.Rating) ? "rating-hold" : "") : ((r.tag === 3) ? (equals(r, model.Rating) ? "rating-weak-sell" : "") : ((r.tag === 4) ? (equals(r, model.Rating) ? "rating-sell" : "") : (equals(r, model.Rating) ? "rating-buy" : ""))))}`,
        children: toString_1(r),
        style: {
            backgroundColor: StockRating__ToColorCode(r),
            color: StockRating__ToTextColorCode(r),
            border: "none",
            fontSize: 1 + "rem",
            width: 80,
            padding: ((5 + "px ") + 15) + "px",
            borderRadius: 5,
            cursor: "pointer",
        },
        onClick: (_arg) => {
            dispatch(new Message(2, [r]));
        },
    });
    const e = ofArray([sectionTitle("Hva mener du om aksjen?"), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: ((10 + "px ") + 10) + "px",
    }], (elems = [ratingButton(new StockRating(4, [])), ratingButton(new StockRating(3, [])), ratingButton(new StockRating(2, [])), ratingButton(new StockRating(1, [])), ratingButton(new StockRating(0, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]);
    if (!model.AutoOpenMode) {
        return inputHolder(e);
    }
    else {
        return createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 10,
                width: 100 + "%",
                maxWidth: 600,
                padding: 10,
            },
            children: Interop_reactApi.Children.toArray(Array.from(e)),
        });
    }
}

function backwardsButton(prev, dispatch) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        width: 100 + "%",
        justifyContent: "flex-end",
    }], (elems_1 = [createElement("button", createObj(ofArray([["style", {
        marginRight: 10,
    }], ["className", "icon-button"], ["onClick", (_arg) => {
        dispatch(new Message(1, [prev]));
    }], (elems = [createElement("span", {
        className: "fas fa-chevron-up",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function drawRatingSummary(model) {
    return summaryHolder([summaryTitle("Du mener aksjen er ett "), createElement("span", {
        style: {
            fontWeight: "bold",
            color: StockRating__ToColorCode(model.Rating),
        },
        children: toString_1(model.Rating),
    })]);
}

function drawSetPeriod(model, dispatch) {
    let elems_3, elems, elements, matchValue_4, elems_1;
    return inputHolder([backwardsButton(new Step(0, []), dispatch), sectionTitle("Hvilken tidshorisont har du?"), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        margin: (((2 + "vh") + " ") + 0) + "px",
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", createObj(toList(delay(() => ((model.Period.tag === 1) ? singleton(["width", 130]) : singleton(["width", 100])))))], (elems = toList(delay(() => {
        const matchValue_1 = model.Period;
        switch (matchValue_1.tag) {
            case 0:
                return singleton(MuiHelpers_createElement(TextField, [["style", {
                    maxWidth: 100,
                }], ["inputProps", {
                    style: {
                        textAlign: "center",
                    },
                }], ["defaultValue", PeriodSpan__ToNumber(matchValue_1.fields[0])], ["fullWidth", true], ["size", "small"], ["variant", "standard"], ["onChange", (e) => {
                    const x = e.target.value;
                    try {
                        const asInt = parse_1(x, 511, false, 32) | 0;
                        if (asInt > 0) {
                            dispatch(new Message(4, [asInt]));
                        }
                        else {
                            dispatch(new Message(8, []));
                        }
                    }
                    catch (matchValue_2) {
                        dispatch(new Message(8, []));
                    }
                }], ["error", !model.ValidPeriod]]));
            case 1:
                return singleton(MuiHelpers_createElement(Input, [["type", "date"], ["value", toString(matchValue_1.fields[0], "yyyy-MM-dd")], ["inputProps", {
                    min: toString(addDays(now(), 1), "yyyy-MM-dd"),
                }], ["onChange", (e_1) => {
                    const x_1 = e_1.target.value;
                    try {
                        dispatch(new Message(5, [parse(x_1)]));
                    }
                    catch (matchValue_3) {
                    }
                }]]));
            default: {
                return empty();
            }
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(FormControl, [["fullWidth", true], ["size", "small"], (elements = [MuiHelpers_createElement(Select, [["variant", "standard"], ["fullWidth", true], ["onChange", (e_2, _arg) => {
        dispatch(new Message(3, [e_2.target.value]));
    }], ["value", (matchValue_4 = model.Period, (matchValue_4.tag === 0) ? PeriodSpan__ToValue(matchValue_4.fields[0]) : "exact")], (elems_1 = [MuiHelpers_createElement(MenuItem, [["value", "days"], ["children", "Dag(er)"]]), MuiHelpers_createElement(MenuItem, [["value", "weeks"], ["children", "Uke(r)"]]), MuiHelpers_createElement(MenuItem, [["value", "months"], ["children", "Måned(er)"]]), MuiHelpers_createElement(MenuItem, [["value", "quarters"], ["children", "Kvartal"]]), MuiHelpers_createElement(MenuItem, [["value", "years"], ["children", "År"]]), MuiHelpers_createElement(MenuItem, [["value", "exact"], ["children", "Spesifik dato"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), pillButtonSmall(empty_1(), "Neste", (_arg_1) => {
        if (model.ValidTarget && model.ValidPeriod) {
            dispatch(new Message(1, [new Step(2, [new TargetStep(0, [])])]));
        }
    })]);
}

function drawPeriodSummary(model) {
    let matchValue, matchValue_1;
    return summaryHolder([summaryTitle((matchValue = model.Period, (matchValue.tag === 1) ? "Du har en tidshorisont til" : ((matchValue.tag === 2) ? "Du har en evig tidshorisont." : "Du har en tidshorisont på"))), elevatedText((matchValue_1 = model.Period, (matchValue_1.tag === 0) ? (`${toString_1(model.Period)}`) : ((matchValue_1.tag === 1) ? toString(matchValue_1.fields[0], "dd/MM/yyyy") : "")))]);
}

function drawSetTarget(model, dispatch, x) {
    return inputHolder(toList(delay(() => append(singleton(backwardsButton(new Step(1, []), dispatch)), delay(() => append(singleton(sectionTitle((x.tag === 1) ? "Skriv inn kursmål" : "Har du ett kursmål?")), delay(() => {
        let elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: ((20 + "px ") + 20) + "px",
            marginTop: 2 + "vh",
        }], (elems_1 = toList(delay(() => {
            let matchValue;
            return (x.tag === 1) ? append((matchValue = model.Currency, (matchValue != null) ? singleton(createElement("span", {
                children: matchValue,
            })) : (empty())), delay(() => {
                let elems;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    width: 100,
                }], (elems = [MuiHelpers_createElement(TextField, [["style", {
                    maxWidth: 100,
                }], ["size", "small"], ["inputProps", {
                    style: {
                        textAlign: "center",
                    },
                }], ["variant", "standard"], ["defaultValue", format('{0:' + "0.00" + '}', model.TargetPrice)], ["onChange", (e) => {
                    const x_1 = e.target.value;
                    try {
                        const asFloat = parse_2(replace(x_1, ",", "."));
                        if (asFloat >= 0) {
                            dispatch(new Message(7, [asFloat]));
                        }
                        else {
                            dispatch(new Message(9, []));
                        }
                    }
                    catch (matchValue_1) {
                        dispatch(new Message(9, []));
                    }
                }], ["error", !model.ValidTarget]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
            })) : append(singleton(pillButtonSmall(empty_1(), "Nei", (_arg) => {
                dispatch(new Message(1, [new Step(3, [])]));
            })), delay(() => singleton(pillButtonSmall(empty_1(), "Ja", (_arg_1) => {
                dispatch(new Message(6, []));
            }))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            if (x.tag === 1) {
                return singleton(pillButtonSmall(singleton_1(["marginTop", 2 + "vh"]), "Neste", (_arg_2) => {
                    if (model.ValidTarget) {
                        dispatch(new Message(1, [new Step(3, [])]));
                    }
                }));
            }
            else {
                return empty();
            }
        }));
    })))))));
}

function drawTargetSummary(model) {
    return summaryHolder(toList(delay(() => append(singleton(summaryTitle(!model.WithTargetPrice ? "Du har ikke ett kursmål." : "Du har ett kursmål på")), delay(() => {
        if (model.WithTargetPrice) {
            let currency;
            const matchValue = model.Currency;
            currency = ((matchValue != null) ? matchValue : "");
            return singleton(elevatedText(`${format('{0:' + "0.00" + '}', model.TargetPrice)}${currency}`));
        }
        else {
            return empty();
        }
    })))));
}

function drawGiveComment(model, dispatch, onSubmit) {
    let elems, elems_1;
    return inputHolder([backwardsButton(model.WithTargetPrice ? (new Step(2, [new TargetStep(1, [])])) : (new Step(1, [])), dispatch), sectionTitle("Gi en kommentar til din vurdering (valgfritt)"), createElement("div", createObj(ofArray([["key", "comment-form-holder"], ["style", {
        width: 100 + "%",
    }], (elems = [createElement(CommentForm, {
        formId: "review-comment-form",
        html: model.Comment,
        onChange: (arg) => {
            dispatch(new Message(10, [arg]));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "space-between",
        width: 100 + "%",
        marginTop: 10,
        padding: 5,
    }], (elems_1 = toList(delay(() => (model.Processing ? singleton(loadingIndicatorSmall()) : append(singleton(pillButtonSmall(empty_1(), "Forkast", (_arg) => {
        if (!model.Processing) {
            dispatch(new Message(11, []));
        }
    })), delay(() => singleton(pillButtonSmall(empty_1(), "Publiser analyse", (_arg_1) => {
        if (!model.Processing) {
            onSubmit();
        }
    }))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]);
}

function drawPublished() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        width: 100 + "%",
        maxWidth: 600,
        padding: 10,
        marginBottom: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
    }], (elems = [summaryTitle("Analyse publisert!")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function registerModal(dispatch) {
    let elements_2, children, text_1, elems, children_1;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(14, [false]));
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Registrer deg for å publisere"]]), (children = ofArray([(text_1 = "Registrer deg for å publisere analysen for denne aksjen. Diskuter aksjer og investeringer med andre brukere på Aksje.io. ", MuiHelpers_createElement(DialogContentText, [["children", text_1]])), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
    }], (elems = [createElement("a", {
        className: "pill-button",
        href: "/sign-up",
        children: "Registrer deg",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton_1(pillButtonSmall(empty_1(), "Lukk", (_arg_2) => {
        dispatch(new Message(14, [false]));
    })), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

export function ReviewForm(reviewFormInputProps) {
    let elems;
    const onPublished = reviewFormInputProps.onPublished;
    const autoOpenMode = reviewFormInputProps.autoOpenMode;
    const assetId = reviewFormInputProps.assetId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(assetId, autoOpenMode));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const matchValue = tryGetReviewDraft(assetId);
        if (matchValue.tag === 0) {
            const x = matchValue.fields[0];
            if (!(() => {
                try {
                    const lu = parse(x.LastUpdated);
                    return totalDays(op_Subtraction(now(), lu)) < 3;
                }
                catch (matchValue_1) {
                    return false;
                }
            })()) {
            }
            else {
                dispatch(new Message(0, [x]));
                if (x.Comment.trim().length > 0) {
                    trySetContent("review-comment-form", x.Comment);
                }
            }
        }
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 100 + "%",
    }], (elems = toList(delay(() => append(singleton(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(15, []));
    })), delay(() => {
        const matchValue_3 = model_1.CurrentStep;
        return (matchValue_3.tag === 1) ? append(singleton(drawRatingSummary(model_1)), delay(() => singleton(drawSetPeriod(model_1, dispatch)))) : ((matchValue_3.tag === 2) ? append(singleton(drawRatingSummary(model_1)), delay(() => append(singleton(drawPeriodSummary(model_1)), delay(() => singleton(drawSetTarget(model_1, dispatch, matchValue_3.fields[0])))))) : ((matchValue_3.tag === 3) ? append(singleton(drawRatingSummary(model_1)), delay(() => append(singleton(drawPeriodSummary(model_1)), delay(() => append(singleton(drawTargetSummary(model_1)), delay(() => append(singleton(drawGiveComment(model_1, dispatch, () => {
            const model_2 = model_1;
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, decoder, data_1, caseStrategy_3, extra_3;
                const validRating = (StockRating__ToInt(model_2.Rating) >= 0) && (StockRating__ToInt(model_2.Rating) <= 100);
                const allValid = (((PeriodType__Validate(model_2.Period)) && validRating) && model_2.ValidPeriod) && model_2.ValidTarget;
                if (allValid && !(isAuthenticated)) {
                    dispatch(new Message(14, [true]));
                    return Promise.resolve();
                }
                else if (allValid) {
                    dispatch(new Message(12, [true]));
                    const request = new AssetReviewForm(model_2.AssetId, StockRating__ToInt(model_2.Rating), PeriodType__ToPeriodEnd(model_2.Period), model_2.Comment.trim(), model_2.WithTargetPrice ? model_2.TargetPrice : undefined);
                    return ((body = Auto_generateBoxedEncoder_437914C6(AssetReviewForm_$reflection(), undefined, undefined, undefined)(request), (decoder = Auto_generateBoxedDecoder_Z6670B51(Post_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                        let properties_2;
                        try {
                            const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty_1()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_2(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                            const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/review", properties_3).then((_arg_1) => {
                                let response_1, decoder_1_1;
                                return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Post_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                                    let matchValue_2;
                                    return Promise.resolve((matchValue_2 = fromString(uncurry2(decoder_1_1), _arg_2), (matchValue_2.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_2.fields[0]])])) : (new FSharpResult$2(0, [matchValue_2.fields[0]]))));
                                })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                            }))));
                            return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                        }
                        catch (exn) {
                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                        }
                    })())))))).then((_arg_3) => {
                        const res = _arg_3;
                        if (res.tag === 1) {
                            dispatch(new Message(13, [res.fields[0]]));
                            return Promise.resolve();
                        }
                        else {
                            onPublished(res.fields[0]);
                            dispatch(new Message(1, [new Step(4, [])]));
                            return Promise.resolve();
                        }
                    });
                }
                else {
                    return Promise.resolve();
                }
            }));
            pr_1.then();
        })), delay(() => (model_1.RegisterModal ? singleton(registerModal(dispatch)) : empty()))))))))) : ((matchValue_3.tag === 4) ? singleton(drawPublished()) : singleton(drawSetRating(model_1, dispatch)))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

