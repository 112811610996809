import { createElement } from "react";
import React from "react";
import { uncurry2, comparePrimitives, createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { TableContainer, TableBody, TableHead, TableCell, TableRow, Table } from "@mui/material";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { cons, empty, ofArray, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { sortBy, map } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { Market_$reflection } from "./Shared/ApiDataTypes.js";
import { obj_type, array_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map as map_1, defaultArg } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { loadingIndicator, snackError } from "./ViewHelpers.js";

function listMarkets(markets) {
    let elems_8, children_4, elements_4, children, elems, children_3;
    return createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        maxWidth: 800,
    }], (elems_8 = [(children_4 = singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_4 = [(children = singleton(MuiHelpers_createElement(TableRow, [["style", {
        backgroundColor: "var(--bg-dark)",
    }], (elems = [MuiHelpers_createElement(TableCell, [["variant", "head"], ["style", {
        color: "#f6f6f6",
    }], ["children", "Ticker"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["style", {
        color: "#f6f6f6",
    }], ["children", "Name"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["style", {
        color: "#f6f6f6",
    }], ["children", "Country"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_3 = map((x_2) => {
        let elems_4, children_1, children_2;
        return MuiHelpers_createElement(TableRow, [["style", {
            backgroundColor: "#fff",
        }], (elems_4 = [(children_1 = singleton(createElement("a", {
            href: toText(printf("/market/%s"))(x_2.Ticker),
            children: x_2.Ticker,
        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton(createElement("a", {
            href: toText(printf("/market/%s"))(x_2.Ticker),
            children: x_2.Name,
        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), MuiHelpers_createElement(TableCell, [["children", x_2.Country]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]);
    }, sortBy((x) => x.Ticker, markets, {
        Compare: comparePrimitives,
    })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])), MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function MarketList() {
    let elems_1, matchValue_1, elems;
    const patternInput = useFeliz_React__React_useState_Static_1505({});
    const updateState = patternInput[1];
    const state = patternInput[0];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Market_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/market", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Market_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })()))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    updateState({
                        ErrorMsg: "Could not load market list.",
                        Markets: unwrap(state.Markets),
                    });
                    return Promise.resolve();
                }
                else {
                    updateState({
                        ErrorMsg: unwrap(state.ErrorMsg),
                        Markets: res.fields[0],
                    });
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_1 = [snackError(state.ErrorMsg, () => {
        updateState({
            Markets: unwrap(state.Markets),
        });
    }), createElement("h2", {
        className: "title is-2",
        children: "Markets",
    }), (matchValue_1 = state.Markets, (matchValue_1 == null) ? createElement("div", createObj(ofArray([["style", {}], (elems = [loadingIndicator()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : listMarkets(matchValue_1))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

